import { useLocation } from "react-router-dom";

import { Header } from "./Header";
import Menu from "./Menu";
import { PageBody } from "./PageBody";
import { SideBar } from "./SideBar";
/**
 * A functional React component as a wrapper for a page with navigation.
 * Renders the page's navbar and content
 */
const Layout = ({ children }) => {
    const { pathname } = useLocation();

    return (
        <main className="main" role="main">
            <div className="wrapper" >
                <SideBar />
                <div className="page-content">
                    <Header />
                    <PageBody pathname={pathname} >
                        {children}
                    </PageBody>
                </div>
                <Menu />
            </div>
        </main>
    );
};

export default Layout;
