import produce from "immer";

export const initialState = {
    weeklyBrokerScore: {}
};

export const ACTIVITY_REPORTS_ACTION_TYPES = Object.freeze({
    FETCH_WEEKLY_BROKER_SCORE: "FETCH_WEEKLY_BROKER_SCORE",

    CLEAR_WEEKLY_BROKER_SCORE: "CLEAR_WEEKLY_BROKER_SCORE"
});

export const activityReportsReducer = produce((draft, action) => {
    switch(action.type) {
        // Weekly Broker Score
        case ACTIVITY_REPORTS_ACTION_TYPES.FETCH_WEEKLY_BROKER_SCORE:
            draft.weeklyBrokerScore = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case ACTIVITY_REPORTS_ACTION_TYPES.CLEAR_WEEKLY_BROKER_SCORE:
            draft.weeklyBrokerScore = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
