import * as Sentry from "@sentry/react";
import throttle from "lodash/throttle";
import { applyMiddleware, compose, createStore } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";

import rootReducer from "./reducers";
import { loadState, saveState } from "./utils/initialize";


export default function configureStore() {
    const composeEnhancers = process.env.NODE_ENV !== "production" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose; // add support for Redux dev tools

    const middleware =
        process.env.NODE_ENV !== "production" ? [reduxImmutableStateInvariant(), thunk] : [thunk];


    const persistedState = loadState();

    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
        actionTransformer: action => {
            if(action.type === "SET_USER_ATTRIBUTE") {
                // Return null to not log the action to Sentry
                return null;
            }

            return action;
        }
    });

    const store = createStore(
        rootReducer,
        persistedState,
        composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
    );

    store.subscribe(
        throttle(() => {
            saveState(store.getState());
        }, 1000)
    );

    return store;
}
