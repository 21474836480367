import React from "react";
import ReactDOM from "react-dom";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import { createBrowserHistory } from "history";

import "./index.scss";
import { Provider } from "react-redux";

import App from "./App";
import { PATH_TO_STATE } from "./constants";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store";
import "react-toastify/dist/ReactToastify.css";
import config from "./config";
import checkIfPageReloaded from "./utils/checkIfPageReloaded";

// const history = createBrowserHistory();

// if(process.env.NODE_ENV === "production") {
//     Sentry.init({
//         dsn: config["sentry"].dsn,
//         integrations: [new BrowserTracing({
//             routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
//             normalizeDepth: 10
//         })],

//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 0.2
//     });
// }

if(config.pendoScript) {
    const pendoScriptElement = document.createElement("script");
    pendoScriptElement.text = config.pendoScript;
    document.head.appendChild(pendoScriptElement);
}

const isReloaded = checkIfPageReloaded();

if(isReloaded && PATH_TO_STATE[window.location.pathname]) {
    const state = JSON.parse(localStorage.getItem("state"));
    const pathKeys = PATH_TO_STATE[window.location.pathname];

    pathKeys.forEach(item => {
        if(item.grandParent) {
            delete state[item.grandParent][item.parent][item.key];
            return;
        }
        if(item.parent) {
            delete state[item.parent][item.key];
            return;
        }
        delete state[item.key];
    });

    localStorage.setItem("state", JSON.stringify(state));
}

const store = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
