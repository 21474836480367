import produce from "immer";

export const initialState = {};

export const ACTIONS_TYPES = Object.freeze({
    SET_USER_ATTRIBUTE: "SET_USER_ATTRIBUTE",
    RESET_USER_DATA: "RESET_USER_DATA"
});

export const userReducer = produce((draft, action) => {
    switch(action.type) {
        case ACTIONS_TYPES.SET_USER_ATTRIBUTE:
            draft = action.payload;
            return draft;

        case ACTIONS_TYPES.RESET_USER_DATA:
            return initialState;

        default:
            return draft;
    }
}, initialState);
