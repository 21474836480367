import cx from "classnames";
import { PulseLoader } from "react-spinners";

import variables from "../../../scss/abstracts/_exports.scss";

const GenericLoader = ({
    color = variables["primary-800"],
    isFullScreen = false
}) => {
    return (
        <div className={cx("w-100 d-flex align-items-center justify-content-center p-5", isFullScreen ? "h-100 p-absolute" : "my-5")}>
            <PulseLoader color={color}/>
        </div>
    );
};

export default GenericLoader;
