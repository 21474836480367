import { ACTIONS_TYPES } from "../reducers/modeReducer";

export const setMode = (mode) => {
    return {
        type: ACTIONS_TYPES.SET_MODE,
        payload: {
            mode
        }
    };
};

export const clearMode = (mode) => {
    return {
        type: ACTIONS_TYPES.CLEAR_MODE,
        payload: {
            mode
        }
    };
};
