import { combineReducers } from "redux";

import { engagementReducer } from "./engagementReportReducer";
import { learningAndDevelopmentReducer } from "./learningAndDevelopmentReducer";
import { overviewReducer } from "./overviewReducer";
import { turnoverReducer } from "./turnoverReducer";

export const peopleAndCultureReducer = combineReducers({
    overview: overviewReducer,
    turnover: turnoverReducer,
    learningAndDevelopment: learningAndDevelopmentReducer,
    engagement: engagementReducer
});
