import cx from "classnames";
import { MdRefresh } from "react-icons/md";

import styles from "../../scss/modules/ErrorBoundary/FallbackUI.module.scss";
import { Header } from "../Common/Layout/Header";

const FallbackUI = () => {

    const handleReload = () => {
        window.location.reload();
    };

    return (
        <>
            <Header />
            <div className={cx(styles["fallback-container"], "d-flex flex-column align-items-center py-5")}>
                <h2>HLE Pulse</h2>
                <p className="lead mt-3 mb-5">Something Went Wrong, Please try <b>re-load</b> or <b>re-login</b> ...</p>
                <button className="btn btn-primary" onClick={handleReload}>
                    <MdRefresh fontSize="large" />
                    <span className="mx-2">Reload</span>
                </button>
            </div>
        </>
    );
};

export default FallbackUI;
