import produce from "immer";

import { componentName } from "../../constants";

export const initialState = {
    [componentName.reports]: {}
};

export const DATA_VERIFICATION_ACTION_TYPES = Object.freeze({
    FETCH_REPORTS_LIST: "FETCH_REPORTS_LIST",
    CLEAR_REPORTS_LIST: "CLEAR_REPORTS_LIST"
});

export const dataVerificationReducer = produce((draft, action) => {
    switch(action.type) {
        case DATA_VERIFICATION_ACTION_TYPES.FETCH_REPORTS_LIST:
            draft[componentName.reports] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case DATA_VERIFICATION_ACTION_TYPES.CLEAR_REPORTS_LIST:
            draft[componentName.reports] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
