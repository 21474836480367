import produce from "immer";

import { componentName } from "../../constants";

export const initialState = {
    customerRating: {},
    internalRating: {},
    googleReviewRating: {},
    productReviewRating: {},
    [componentName.productReviewRatingTable]: {},
    [componentName.googleReviewRatingTable]: {},
    [componentName.brokers]: {},
    [componentName.supports]: {},
    [componentName.brokerRating]: {},
    [componentName.treePlantation]: {},
    [componentName.leadNps]: {},
    [componentName.leadFeedback]: {}
};

export const REVIEW_AND_RATING_ACTION_TYPES = Object.freeze({
    FETCHING_BROKER_RATING: "FETCHING_BROKER_RATING",
    FETCHING_CUSTOMER_RATING: "FETCHING_CUSTOMER_RATING",
    FETCHING_INTERNAL_RATING: "FETCHING_INTERNAL_RATING",
    FETCHING_PRODUCT_REVIEW_RATING: "FETCHING_PRODUCT_REVIEW_RATING",
    FETCHING_GOOGLE_REVIEW_RATING: "FETCHING_GOOGLE_REVIEW_RATING",
    FETCHING_PRODUCT_REVIEW_RATING_TABLE: "FETCHING_PRODUCT_REVIEW_RATING_TABLE",
    FETCHING_GOOGLE_REVIEW_RATING_TABLE: "FETCHING_GOOGLE_REVIEW_RATING_TABLE",
    FETCHING_BROKERS_LIST: "FETCHING_BROKERS_LIST",
    FETCHING_SUPPORTS_LIST: "FETCHING_SUPPORTS_LIST",
    FETCHING_TREE_PLANTATION_DATA: "FETCHING_TREE_PLANTATION_DATA",
    FETCHING_LEAD_NPS_DATA: "FETCHING_LEAD_NPS_DATA",
    FETCHING_LEAD_FEEDBACK_DATA: "FETCHING_LEAD_FEEDBACK_DATA",

    CLEAR_BROKER_RATING: "CLEAR_BROKER_RATING",
    CLEAR_CUSTOMER_RATING: "CLEAR_CUSTOMER_RATING",
    CLEAR_INTERNAL_RATING: "CLEAR_INTERNAL_RATING",
    CLEAR_PRODUCT_REVIEW_RATING: "CLEAR_PRODUCT_REVIEW_RATING",
    CLEAR_GOOGLE_REVIEW_RATING: "CLEAR_GOOGLE_REVIEW_RATING",
    CLEAR_PRODUCT_REVIEW_RATING_TABLE: "CLEAR_PRODUCT_REVIEW_RATING_TABLE",
    CLEAR_GOOGLE_REVIEW_RATING_TABLE: "CLEAR_GOOGLE_REVIEW_RATING_TABLE",
    CLEAR_BROKERS_LIST: "CLEAR_BROKERS_LIST",
    CLEAR_SUPPORTS_LIST: "CLEAR_SUPPORTS_LIST",
    CLEAR_TREE_PLANTATION_DATA: "CLEAR_TREE_PLANTATION_DATA",
    CLEAR_LEAD_NPS_DATA: "CLEAR_LEAD_NPS_DATA",
    CLEAR_LEAD_FEEDBACK_DATA: "CLEAR_LEAD_FEEDBACK_DATA"
});

export const reviewAndRatingReducer = produce((draft, action) => {
    switch(action.type) {
        // Broker Rating
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_BROKER_RATING:
            draft.brokerRating = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_BROKER_RATING:
            draft.brokerRating = null;
            return draft;

        // Customer Rating
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_CUSTOMER_RATING:
            draft.customerRating = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_CUSTOMER_RATING:
            draft.customerRating = null;
            return draft;

        // Internal Rating
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_INTERNAL_RATING:
            draft.internalRating = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_INTERNAL_RATING:
            draft.internalRating = null;
            return draft;

        // Google Review Rating Table
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_GOOGLE_REVIEW_RATING_TABLE:
            draft[componentName.googleReviewRatingTable] = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_GOOGLE_REVIEW_RATING_TABLE:
            draft[componentName.googleReviewRatingTable] = null;
            return draft;

        // Google Review Rating Chart
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_GOOGLE_REVIEW_RATING:
            draft.googleReviewRating = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_GOOGLE_REVIEW_RATING:
            draft.googleReviewRating = null;
            return draft;

        // Product Review Rating Table
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_PRODUCT_REVIEW_RATING_TABLE:
            draft[componentName.productReviewRatingTable] = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_PRODUCT_REVIEW_RATING_TABLE:
            draft[componentName.productReviewRatingTable] = null;
            return draft;

        // Product Review Rating Chart
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_PRODUCT_REVIEW_RATING:
            draft.productReviewRating = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_PRODUCT_REVIEW_RATING:
            draft.productReviewRating = null;
            return draft;

        // Broker List
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_BROKERS_LIST:
            draft[componentName.brokers] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_BROKERS_LIST:
            draft[componentName.brokers] = null;
            return draft;

        // Support List
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_SUPPORTS_LIST:
            draft[componentName.supports] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_SUPPORTS_LIST:
            draft[componentName.supports] = null;
            return draft;

        // Tree Plantation
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_TREE_PLANTATION_DATA:
            draft[componentName.treePlantation] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_TREE_PLANTATION_DATA:
            draft[componentName.treePlantation] = null;
            return draft;

        // Lead NPS
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_LEAD_NPS_DATA:
            draft[componentName.leadNps] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_LEAD_NPS_DATA:
            draft[componentName.leadNps] = null;
            return draft;

        // Lead Feedback
        case REVIEW_AND_RATING_ACTION_TYPES.FETCHING_LEAD_FEEDBACK_DATA:
            draft[componentName.leadFeedback] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case REVIEW_AND_RATING_ACTION_TYPES.CLEAR_LEAD_FEEDBACK_DATA:
            draft[componentName.leadFeedback] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
