import produce from "immer";

export const initialState = {
    ldasReport: {},
    refinanceAndPurchase: {},
    ldasBrokerYearly: {},
    ldasIsYearly: {}
};

export const LDAS_REPORTS_ACTION_TYPES = Object.freeze({
    FETCHING_LDAS_DATA: "FETCHING_LDAS_DATA",
    FETCHING_REFINANCE_AND_PURCHASE_DATA: "FETCHING_REFINANCE_AND_PURCHASE_DATA",
    FETCHING_LDAS_BROKER_YEARLY_DATA: "FETCHING_LDAS_BROKER_YEARLY_DATA",
    FETCHING_LDAS_IS_YEARLY_DATA: "FETCHING_LDAS_IS_YEARLY_DATA",

    CLEAR_LDAS_DATA: "CLEAR_LDAS_DATA",
    CLEAR_REFINANCE_AND_PURCHASE_DATA: "CLEAR_REFINANCE_AND_PURCHASE_DATA",
    CLEAR_LDAS_BROKER_YEARLY_DATA: "CLEAR_LDAS_BROKER_YEARLY_DATA",
    CLEAR_LDAS_IS_YEARLY_DATA: "CLEAR_LDAS_IS_YEARLY_DATA"
});

export const ldasReportsReducer = produce((draft, action) => {
    switch(action.type) {
        // LDAS Broker Yearly Data
        case LDAS_REPORTS_ACTION_TYPES.FETCHING_LDAS_BROKER_YEARLY_DATA:
            draft.ldasBrokerYearly = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case LDAS_REPORTS_ACTION_TYPES.CLEAR_LDAS_BROKER_YEARLY_DATA:
            draft.ldasBrokerYearly = null;
            return draft;

        // LDAS IS Yearly Data
        case LDAS_REPORTS_ACTION_TYPES.FETCHING_LDAS_IS_YEARLY_DATA:
            draft.ldasIsYearly = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case LDAS_REPORTS_ACTION_TYPES.CLEAR_LDAS_IS_YEARLY_DATA:
            draft.ldasIsYearly = null;
            return draft;

        // Refinanc And Purchase Data
        case LDAS_REPORTS_ACTION_TYPES.FETCHING_REFINANCE_AND_PURCHASE_DATA:
            draft.refinanceAndPurchase = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case LDAS_REPORTS_ACTION_TYPES.CLEAR_REFINANCE_AND_PURCHASE_DATA:
            draft.refinanceAndPurchase = null;
            return draft;

        // LDAS Data
        case LDAS_REPORTS_ACTION_TYPES.FETCHING_LDAS_DATA:
            draft.ldasReport = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case LDAS_REPORTS_ACTION_TYPES.CLEAR_LDAS_DATA:
            draft.ldasReport = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
