export const TIME_ZONES = {
    "Aus/Sydney": "Australia/Sydney",
    "Nepal": "Nepal/Kathmandu"
    // "None": "None"
};

// For resetting states on reload
export const PATH_TO_STATE = {
    "/": [{ key: "overview" }, { key: "weeklyData", parent: "mortgageOverview", grandParent: "mortgage" }],
    "/mortgage": [{ key: "mortgageOverview", parent: "mortgage" }],
    // "/mortgage/discharge": [{ key: "discharge", parent: "mortgage" }],
    "/mortgage/marketing": [{ key: "marketing", parent: "mortgage" }],
    "/mortgage/email-marketing": [{ key: "emailMarketing", parent: "mortgage" }],
    "/mortgage/reviews-and-ratings": [{ key: "reviewAndRating", parent: "mortgage" }],
    "/mortgage/ldas-reports": [{ key: "ldasReports", parent: "mortgage" }],
    "/mortgage/activity-reports": [{ key: "activityReports", parent: "mortgage" }],
    "/mortgage/sales-reports": [{ key: "salesReports", parent: "mortgage" }],
    "/mortgage/workload-reports": [{ key: "workloadReports", parent: "mortgage" }],
    "/mortgage/broker-reports": [{ key: "brokerReports", parent: "mortgage" }],
    "/mortgage/lead-rating-report": [{ key: "leadRatingReport", parent: "mortgage" }],
    "/outsourcing": [{ key: "outSourcing" }],
    "/operations": [{ key: "operationOverview", parent: "operations" }],
    "/operations/technology": [{ key: "technology", parent: "operations" }],
    "/operations/people-and-culture": [{ key: "peopleAndCulture", parent: "operations" }],
    "/operations/people-and-culture/turnover-trends": [{ key: "turnover", parent: "peopleAndCulture", grandParent: "operations" }],
    "/operations/people-and-culture/learning-and-development": [{ key: "learningAndDevelopment", parent: "peopleAndCulture", grandParent: "operations" }],
    "/operations/people-and-culture/engagement-reports": [{ key: "engagement", parent: "peopleAndCulture", grandParent: "operations" }],
    "/operations/learning-hub": [{ key: "learningHub", parent: "operations" }],
    "/operations/call-analysis": [{ key: "callAnalysis", parent: "operations" }],
    "/operations/chat-analysis": [{ key: "chatAnalysis", parent: "operations" }],
    "/forms/discharge": [{ key: "discharge", parent: "forms" }],
    "/forms/data-verification": [{ key: "dataVerification", parent: "forms" }],
    "/sankey": [{ key: "sankey" }]
};

export const componentName = {
    // Company Overview
    webLeads: "webLeads",
    pipelineReport: "pipelineReport",
    leadOpportunityReport: "leadOpportunityReport",
    weeklyLeadReport: "weeklyLeadReport",
    dailyWeeklyBrokerReport: "dailyWeeklyBrokerReport",
    dailyWeeklyISReport: "dailyWeeklyISReport",
    notContactData: "notContactData",
    brokerNotContactData: "brokerNotContactData",
    isNotContactData: "isNotContactData",
    insideSalesWorkload: "insideSalesWorkload",
    leadConversion: "leadConversion",
    leadRatingReport: "leadRatingReport",

    // Mortgage Broking -> Overview
    leadEnquiries: "leadEnquiries",
    arrearsRate: "arrearsRate",
    arrearsRunOff: "arrearsRunOff",
    leadMileStone: "leadMileStone",
    weeklyData: "weeklyData",
    netLoanBook: "netLoanBook",
    topLenders: "topLenders",
    googleAnalyticsVisitor: "googleAnalyticsVisitor",
    referralSettled: "referralSettled",
    referralSubmission: "referralSubmission",

    // Mortgage Broking -> Broker Reports
    loanBook: "loanBook",
    leadQuality: "leadQuality",

    // Mortgage Broking -> Discharge
    retention: "retention",
    brokerRetention: "brokerRetention",
    lenderRetention: "lenderRetention",

    // Mortgage Broking -> Email Marketing
    newsLetterRba: "newsLetterRba",
    newsLetterEMag: "newsLetterEMag",
    newsLetterConnective: "newsLetterConnective",

    // Mortgage Broking -> Review And Rating
    leadNps: "leadNps",
    treePlantation: "treePlantation",
    brokerRating: "brokerRating",
    leadFeedback: "leadFeedback",
    customerRating: "customerRating",
    internalRating: "internalRating",
    googleReviewRating: "googleReviewRating",
    productReviewRating: "productReviewRating",
    googleReviewRatingTable: "googleReviewRatingTable",
    productReviewRatingTable: "productReviewRatingTable",
    brokers: "brokers",
    supports: "supports",

    // Mortgage Broking -> Ldas Reports
    ldasReport: "ldasReport",
    refinanceAndPurchase: "refinanceAndPurchase",
    ldasBrokerYearly: "ldasBrokerYearly",
    ldasIsYearly: "ldasIsYearly",
    pageSegment: "pageSegment",

    // Mortgage Broking -> Activity Reports
    weeklyBrokerScore: "weeklyBrokerScore",

    // Mortgage Broking -> Sales Reports
    monthlySales: "monthlySales",

    // Mortgage Broking -> Workload Reports
    lpsWorkloads: "lpsWorkloads",
    crWorkloads: "crWorkloads",
    caWorkloads: "caWorkloads",
    dataProcessingWorkloads: "dataProcessingWorkloads",
    outsourcingWorkloads: "outsourcingWorkloads",

    // Outsourcing
    outsourcingStaff: "outsourcingStaff",
    outsourcingRevenue: "outsourcingRevenue",
    outsourcingLps: "outsourcingLps",
    outsourcingCa: "outsourcingCa",

    // Operations -> Overview
    staffAllocation: "staffAllocation",
    recruiterNps: "recruiterNps",

    // Operations -> Learning Hub
    learningHub: "learningHub",

    // Operations -> People And Culture
    staffComposition: "staffComposition",
    staffByYear: "staffByYear",
    staffByCountry: "staffByCountry",
    staffByDepartment: "staffByDepartment",
    staffByAge: "staffByAge",
    staffByGender: "staffByGender",

    // Operations -> People And Culture -> Turnover Trends
    turnoverType: "turnoverType",
    turnoverRate: "turnoverRate",
    turnoverDuration: "turnoverDuration",
    turnoverDepartment: "turnoverDepartment",
    turnoverPerformance: "turnoverPerformance",
    probation: "probation",

    // Operations -> People And Culture -> Learning And Development
    training: "training",
    goodReadsBonus: "goodReadsBonus",
    brokerAccreditation: "brokerAccreditation",

    // Operations -> People And Culture -> Engagement Reports
    employeeAssistance: "employeeAssistance",
    engagementScore: "engagementScore",
    onboarding: "onboarding",
    parentalBonus: "parentalBonus",
    rewards: "rewards",
    semiAnnualEngagement: "semiAnnualEngagement",
    weddingGifts: "weddingGifts",
    workAnniversary: "workAnniversary",

    // Operations -> Call Analysis
    peakHourCall: "peakHourCall",
    brokerAvailability: "brokerAvailability",
    agentCallInfo: "agentCallInfo",

    // Operations -> Chat Analysis
    agentChatData: "agentChatData",
    agentChatStatus: "agentChatStatus",
    peakChatData: "peakChatData",
    chatDetails: "chatDetails",
    agentOnlineStatus: "agentOnlineStatus",
    agentChatSchedule: "agentChatSchedule",

    // Sankey
    sankey: "sankey",
    sankeyStats: "sankeyStats",
    safSentDropoffStats: "safSentDropoffStats",

    // Forms
    dischargeLenders: "dischargeLenders",
    dischargeBrokers: "dischargeBrokers",
    dischargeLps: "dischargeLps",
    discharge: "discharge",

    // Forms -> Data Verification
    reports: "reports"
};

// 📝 Note: Please maintain the order
export const componentDetails = {
    // Company Overview 👇
    pipelineReport: {
        name: componentName.pipelineReport,
        title: "Weekly 3 Months Rolling Pipeline Report",
        // For navigation pop-up 👇
        id: "pipeline-report",
        page: "/"
    },
    leadOpportunityReport: {
        name: componentName.leadOpportunityReport,
        title: "Lead Opportunity Report",
        tooltip: `This report shows the data of lead distribution, dialpad and zopium and shows how many opportunies we have on each each week which we could convert to actual leads. The total opportunity is calculated by summing lead distribution, all calls and all chats.`,
        // For navigation pop-up 👇
        id: "lead-opportunity-report",
        page: "/"
    },
    weeklyLeadReport: {
        name: componentName.weeklyLeadReport,
        title: "Weekly Lead Indicator Report",
        tooltip: `This graph shows the trend of lead, SAF sent, SAF received, submission and HBI Signups we receive every week`,
        id: "weekly-lead-indicator-report",
        page: "/"
    },
    notContactData: {
        name: componentName.notContactData,
        title: "Not Contacted Lead Report",
        tooltip: `The reports shows which leads were not contacted by brokers within 24 hours`,
        id: "not-contacted-lead-report",
        page: "/"
    },
    brokerNotContactData: {
        name: componentName.brokerNotContactData,
        title: "Not Contacted Lead Report for Brokers"
    },
    isNotContactData: {
        name: componentName.isNotContactData,
        title: "Not Contacted Lead Report for Inside Sales"
    },
    dailyWeeklyBrokerReport: {
        name: componentName.dailyWeeklyBrokerReport,
        title: "Phone Enquiry Report for Brokers",
        tooltip: `This report shows the outbound calls, inbound calls and missed calls of each broker and how many leads were recorded in mercury.`,
        id: "broker-phone-enquiry-report",
        page: "/"
    },
    insideSalesWorkload: {
        name: componentName.insideSalesWorkload,
        title: "Inside Sales Workload Report",
        tooltip: `This report shows how many leads were received by an IS team , how many calls did IS team made and out of them how many successful leads were transferred to brokers for further assessment`,
        id: "inside-sales-workload-report",
        page: "/"
    },
    dailyWeeklyISReport: {
        name: componentName.dailyWeeklyISReport,
        title: "Phone Enquiry Report for Inside Sales",
        tooltip: `This report shows the outbound calls, inbound calls and missed calls of each IS Team and how many leads were recorded in mercury.`,
        id: "inside-sales-phone-enquiry-report",
        page: "/"
    },
    // Company Overview ☝

    // Mortgage Broking -> Overview 👇
    weeklyData: {
        name: componentName.weeklyData,
        title: "Weekly Lead Indicator Report",
        tooltip: "Lead is potential customers for our business, coming from any source. When a client is provided SAF by the broker to fill, then it is called SAF sent. When the client returns back the filled SAF, then it is called SAF received. When lead is submitted to lender, it is submission.",
        // For navigation pop-up 👇
        id: "weekly-lead-indicator-report",
        page: "/mortgage"
    },
    leadEnquiries: {
        name: componentName.leadEnquiries,
        title: "Lead Enquiries Report",
        tooltip: `X-axis represents the days of the week and y-axis represents hours in a day. Each cell value is the total number of  lead 
        generated on the particular hour of a weekday.`,
        id: "lead-enquiries-report",
        page: "/mortgage"
    },
    googleAnalyticsVisitor: {
        name: componentName.googleAnalyticsVisitor,
        title: "Google Analytics Visitor",
        tooltip: `X-axis represents the date for selected period, Y-axis represents the visitor number. Value is the total visitor for that date.`,
        id: "google-analytics-visitor",
        page: "/mortgage"
    },
    leadMileStone: {
        name: componentName.leadMileStone,
        title: "Submission, Settlement and Discharge",
        tooltip: "Submission is the number of submitted files to the lender. <br /> Settlement is  the number of files which has been settled. <br /> A discharge of mortgage is removing a home loan from the title of your property. <br /><i>Note that the figures of discharges delays by a month</i>",
        id: "submission-settlement-discharge",
        page: "/mortgage"
    },
    arrearsRate: {
        name: componentName.arrearsRate,
        title: "Mortgage Arrears Rate",
        rateType: "Arrears",
        tooltip: `Arrears occur when you have not made the required payments on your home loan. This includes missed repayments, late and overdue payments. <br /><i>Note: Arrears Data delays by a month. <br />Formula of Arrears rate = Total Arrears Volume / Total Loan Book Balance</i>`,
        // For navigation pop-up 👇
        id: "mortgage-arrears-rate",
        page: "/mortgage"
    },
    arrearsRunOff: {
        name: componentName.arrearsRunOff,
        title: "Mortgage Run-off Rate",
        rateType: "Run-off",
        tooltip: "Runoff is reduction of a loan portfolio as loans are paid off at scheduled maturity dates, or when borrowers pre-pays their loans. <br /><i>Note: Run Off Data delays by a month. <br /> Formula of Run off rate= [Loan Book Balance (Last month)-{Loan Book Balance (Current month) - Settled Loan Balance (Current Month)}]/Loan Book Balance (Last month) </i>",
        id: "mortgage-arrears-runoff",
        page: "/mortgage"
    },
    netLoanBook: {
        name: componentName.netLoanBook,
        title: "Loan Book Vs Month",
        tooltip: "A loan book is a book kept by a financial institution that totals the amount of loans that have been given out over a certain period and it shows the details of the borrowers. <br /><i> Note: Loan Book Balances delays by a month</i>",
        id: "loan-book-vs-month",
        page: "/mortgage"
    },
    topLenders: {
        name: componentName.topLenders,
        title: "Submission & Settlement - Top 10 Lenders",
        tooltip: `This graph shows top 10 lenders in terms of settlement and submission`,
        id: "top-10-lenders",
        page: "/mortgage"
    },
    referralSettled: {
        name: componentName.referralSettled,
        dataType: "Settled",
        title: "Repeat, Referral and Self generated business in number (Settled)",
        tooltip: `This report shows our repeat , referral and self generated customer whose loans are already settled in terms of both number and dollar value.
        <br />
        <ol>
            <li>Repeat customer includes - Repeat Customer, Repeat Customer - 2 Year Refinance, Repeat Customer - Sale of Property, Orphan - Repeat Customer</li>
            <li>Referral customer includes - Referral from Customer ,Referral from HLE Staff, Referral from Otto, Referral from Otto's referral (Singh), Referral from Otto's Referrers (eg Katherine Boyd), Orphan - Referral</li>
            <li>Self generated customer includes - Broker's own professional referrer (e.g. Accountant), Family, Friend</li>
        </ol>`,
        // For navigation pop-up 👇
        id: "repeat-referral-settled",
        page: "/mortgage"
    },
    referralSubmission: {
        name: componentName.referralSubmission,
        dataType: "Submission",
        title: "Repeat, Referral and Self generated business in number (Submission)",
        tooltip: `This report shows our repeat , referral and self generated customer whose loans are submitted in terms of both number and dollar value.
        <br />
        <ol>
            <li>Repeat customer includes: Repeat Customer, Repeat Customer - 2 Year Refinance, Repeat Customer - Sale of Property, Orphan - Repeat Customer</li>
            <li>Referral customer includes: Referral from Customer ,Referral from HLE Staff, Referral from Otto, Referral from Otto's referral (Singh), Referral from Otto's Referrers (eg Katherine Boyd), Orphan - Referral</li>
            <li>Self generated customer includes: Broker's own professional referrer (e.g. Accountant), Family, Friend</li>
        </ol>`,
        id: "repeat-referral-submission",
        page: "/mortgage"
    },
    // Mortgage Broking -> Overview ☝

    // Mortgage Broking -> Broker Reports 👇
    [componentName.loanBook]: {
        name: componentName.loanBook,
        title: "Loan Book Report",
        tooltip: `
            Loan Book Report of the brokers by fiscal-year/month.
            <br />
            There are three filter options:
            <ol>
                <li>By Month: Loan Book Amount of each month grouped by brokers</li>
                <li>By Broker: Loan Book amount of each broker grouped by months</li>
                <li>Default: Loan book amount of each month for the given fiscal year</li>
            </ol>
        `,
        id: "loan-book-report",
        page: "/mortgage/broker-reports"
    },
    [componentName.leadQuality]: {
        name: componentName.leadQuality,
        title: "Lead Quality Report",
        id: "lead-quality-report",
        page: "/mortgage/broker-reports"
    },
    // Mortgage Broking -> Broker Reports ☝

    // Mortgage Broking -> Discharge 👇
    retention: {
        name: componentName.retention,
        title: "Customer Retention after Discharge",
        id: "clawback-retention-summary",
        page: "/mortgage/discharge"
    },
    brokerRetention: {
        name: componentName.brokerRetention,
        title: "Customer Retention by Broker",
        id: "clawback-broker-retention",
        page: "/mortgage/discharge"
    },
    lenderRetention: {
        name: componentName.lenderRetention,
        title: "Customer Retention by Lender",
        id: "clawback-lender-retention",
        page: "/mortgage/discharge"
    },
    // Mortgage Broking -> Discharge ☝

    // Mortgage Broking -> Email Marketing 👇
    newsLetterConnective: {
        name: componentName.newsLetterConnective,
        title: "Connective Newsletter to Clients",
        type: "connective",
        // For navigation pop-up 👇
        id: "connective-newsletter",
        page: "/mortgage/email-marketing"
    },
    newsLetterRba: {
        name: componentName.newsLetterRba,
        title: "RBA Newsletter in Number",
        type: "RBA",
        tooltip: "This is monthly RBA email sent to our clients",
        id: "rba-newsletter",
        page: "/mortgage/email-marketing"
    },
    newsLetterEMag: {
        name: componentName.newsLetterEMag,
        title: "Connective Newsletter E-Mag to Clients",
        type: "eMag",
        tooltip: "This is monthly eMag email sent to our clients",
        id: "emag-newsletter",
        page: "/mortgage/email-marketing"
    },
    // Mortgage Broking -> Email Marketing ☝

    // Mortgage Broking -> Review And Rating 👇
    [componentName.treePlantation]: {
        name: componentName.treePlantation,
        title: "Tree Plantation Report",
        tooltip: `This report shows the total product and google reviews received. 
        The figure is almost close to the actual tree planted count, which is updated bi-annually by our partner organization.`,
        // For navigation pop-up 👇
        id: "tree-plantation-report",
        page: "/mortgage/reviews-and-ratings"
    },
    productReviewRating: {
        name: componentName.productReviewRating,
        title: "Product Review Rating",
        reviewType: "Product",
        tooltip: "Ratings received by Home Loan Experts on Product Review Website",
        // For navigation pop-up 👇
        id: "product-review-rating",
        page: "/mortgage/reviews-and-ratings"
    },
    googleReviewRating: {
        name: componentName.googleReviewRating,
        title: "Google Review Rating",
        reviewType: "Google",
        tooltip: "Ratings received by Home Loan Experts on Google",
        id: "google-review-rating",
        page: "/mortgage/reviews-and-ratings"
    },
    internalRating: {
        name: componentName.internalRating,
        title: "Internal Rating",
        ratingType: "Internal",
        tooltip: "Ratings received by Brokers from LPS & Ratings received by LPS from Brokers via Typeform",
        id: "internal-rating",
        page: "/mortgage/reviews-and-ratings"
    },
    customerRating: {
        name: componentName.customerRating,
        title: "Customer Rating",
        ratingType: "Customer",
        tooltip: "Ratings received by Brokers & LPS from the clients via Typeform",
        id: "customer-rating",
        page: "/mortgage/reviews-and-ratings"
    },
    brokerRating: {
        name: componentName.brokerRating,
        title: "Customer Rating for Broker (Settled)",
        // For navigation pop-up 👇
        id: "broker-rating",
        page: "/mortgage/reviews-and-ratings"
    },
    [componentName.leadFeedback]: {
        name: componentName.leadFeedback,
        title: "Lead Feedback",
        // For navigation pop-up 👇
        id: "lead-feedback",
        page: "/mortgage/reviews-and-ratings"
    },
    leadNps: {
        name: componentName.leadNps,
        title: "Lead Nps Score",
        // For navigation pop-up 👇
        id: "lead-nps-score",
        page: "/mortgage/reviews-and-ratings"
    },
    // Mortgage Broking -> Review And Rating ☝

    // Mortgage Broking -> Ldas Reports 👇
    refinanceAndPurchase: {
        name: componentName.refinanceAndPurchase,
        title: "Refinance And Purchase",
        tooltip: `This report shows number of purchase and refinance deal we have received`,
        // For navigation pop-up 👇
        id: "refinance-and-purchase",
        page: "/mortgage/ldas-reports"
    },
    ldasReport: {
        name: componentName.ldasReport,
        title: "Monthly Lead Distribution",
        tooltip: {
            broker: `This report shows Lead Distribution in terms of lead segment of Broker Team`,
            insideSales: `This report shows Lead Distribution in terms of lead segment of IS Team`
        },
        id: "monthly-lead-distribution",
        page: "/mortgage/ldas-reports"
    },
    ldasBrokerYearly: {
        name: componentName.ldasBrokerYearly,
        title: "LDAS Yearly Comparison Report for Brokers",
        tooltip: `This report shows yearly Lead Distribution Report of Broker Team`,
        id: "yearly-comparison-report-broker",
        page: "/mortgage/ldas-reports"
    },
    ldasIsYearly: {
        name: componentName.ldasIsYearly,
        title: "LDAS Yearly Comparison Report for Inside Sales",
        tooltip: `This report shows yearly Lead Distribution Report of IS Team`,
        id: "yearly-comparison-report-IS",
        page: "/mortgage/ldas-reports"
    },
    [componentName.pageSegment]: {
        name: componentName.pageSegment,
        title: "Top Conversion Page of Web Lead",
        tooltip: "This report shows top conversion pages of Complete Inquiry Form Only",
        id: "page-segment-report",
        page: "/mortgage/marketing"
    },
    // Mortgage Broking -> Ldas Reports ☝

    // Mortgage Broking -> Activity Reports 👇
    weeklyBrokerScore: {
        name: componentName.weeklyBrokerScore,
        title: "Weekly Activity Score of Broker",
        tooltip: `
        <strong>Weekly Activity Score for Broker:</strong>
        <span>Activity score of a broker is a report which shows activity of each broker based on various metrics such as number of leads taken, total number of calls made (only landline), number of emails sent, number of SAF sent, number of SAF received and number of Submissions in that particular time frame / week.</span>
        <div style="margin: 8px 0" />
        <strong>Threshold: </strong><span>30</span>
        `,
        // For navigation pop-up 👇
        id: "weekly-broker-score",
        page: "/mortgage/activity-reports"
    },
    // Mortgage Broking -> Activity Reports ☝

    // Mortgage Broking -> Sales Reports 👇
    [componentName.monthlySales]: {
        name: componentName.monthlySales,
        title: "Monthly Sales Report",
        // For navigation pop-up 👇
        id: "monthly-sales-report",
        page: "/mortgage/sales-reports"
    },
    // Mortgage Broking -> Sales Reports ☝

    // Mortgage Broking -> Workload Reports 👇
    [componentName.lpsWorkloads]: {
        name: componentName.lpsWorkloads,
        title: "LPS and Learning Hub Workload Reports",
        // For navigation pop-up 👇
        id: "lps-workload-report",
        page: "/mortgage/workload-reports"
    },
    [componentName.caWorkloads]: {
        name: componentName.caWorkloads,
        title: "CA Workload Reports",
        // For navigation pop-up 👇
        id: "ca-workload-report",
        page: "/mortgage/workload-reports"
    },
    [componentName.crWorkloads]: {
        name: componentName.crWorkloads,
        title: "CR Workload Reports",
        tooltip: `
            On the third of every month, prior month data for monthly data will be accessible. 
            For e.g. January data will be available on 3rd of February
        `,
        // For navigation pop-up 👇
        id: "cr-workload-report",
        page: "/mortgage/workload-reports"
    },
    [componentName.dataProcessingWorkloads]: {
        name: componentName.dataProcessingWorkloads,
        title: "Data Processing Workload Reports",
        // For navigation pop-up 👇
        id: "data-processing-workload-report",
        page: "/mortgage/workload-reports"
    },
    [componentName.outsourcingWorkloads]: {
        name: componentName.outsourcingWorkloads,
        title: "Outsourcing Workload Reports",
        // For navigation pop-up 👇
        id: "outsourcing-workload-report",
        page: "/mortgage/workload-reports"
    },
    // Mortgage Broking -> Workload Reports ☝

    // Mortgage Broking -> Lead Rating Reports 👇
    leadRatingReport: {
        name: componentName.leadConversion,
        title: "Lead Rating Report",
        // For navigation pop-up 👇
        id: "lead-quality-system",
        page: "/mortgage/lead-rating-report"
    },
    // Mortgage Broking -> Lead Rating Reports ☝

    // Outsourcing 👇
    outsourcingStaff: {
        name: componentName.outsourcingStaff,
        title: "Total Staff vs Total Partner",
        tooltip: `This graph shows total staffs and partners we have on each month`,
        // For navigation pop-up 👇
        id: "staff-vs-partner",
        page: "/outsourcing",
        type: "staff"
    },
    outsourcingRevenue: {
        name: componentName.outsourcingRevenue,
        title: "Outsourcing Revenue vs Month",
        tooltip: `This graph shows the revenue we have received from Outsourcing Team each month. The figure is in AUD.`,
        id: "outsourcing-revenue",
        page: "/outsourcing",
        type: "revenue"
    },
    outsourcingLps: {
        name: componentName.outsourcingLps,
        title: "LPS NPS Score",
        tooltip: `The rating received by LPS from their partners`,
        id: "lps-nps-score",
        page: "/outsourcing",
        type: "lps"
    },
    outsourcingCa: {
        name: componentName.outsourcingCa,
        title: "CA NPS Score",
        tooltip: `The rating received by CA from their partners`,
        id: "ca-nps-score",
        page: "/outsourcing",
        type: "ca"
    },
    // Outsourcing ☝

    // Operations -> Overview 👇
    staffAllocation: {
        name: componentName.staffAllocation,
        title: "Staff Allocation in each Department",
        tooltip: "This graph shows total staffs in each department",
        // For navigation pop-up 👇
        id: "staff-allocation",
        page: "/operations"
    },
    recruiterNps: {
        name: componentName.recruiterNps,
        title: "Recruiter NPS",
        tooltip: "The rating received by recuiters from the candidate",
        id: "recruiter-nps",
        page: "/operations"
    },
    // Operations -> Overview ☝

    // Operations -> Technology 👇
    leadConversion: {
        name: componentName.leadConversion,
        title: "Web Lead Conversion Report",
        // For navigation pop-up 👇
        id: "lead-conversion-report",
        page: "/operations/technology"
    },
    webLeads: {
        name: componentName.webLeads,
        title: "Monthly Web Leads Report",
        tooltip: "This table displays the total number of leads that our website has generated using its many tools, including the calculators, AI Chatbot, enquiry forms, etc.",
        // For navigation pop-up 👇
        id: "web-leads-report",
        page: "/operations/technology"
    },
    // Operations -> Technology ☝

    // Operations -> Learning Hub 👇
    learningHub: {
        name: componentName.learningHub,
        title: "Learning Hub Reports",
        // For navigation pop-up
        id: "learning-hub",
        page: "/operations/learning-hub"
    },
    // Operations -> Learning Hub ☝

    // Operations -> People And Culture 👇
    [componentName.staffByYear]: {
        name: componentName.staffByYear,
        title: "Total Headcount - Year Wise",
        id: "staff-by-year",
        page: "/operations/people-and-culture"
    },
    [componentName.staffComposition]: {
        name: componentName.staffComposition,
        title: "Staff Composition Reports"
    },
    [componentName.staffByCountry]: {
        name: componentName.staffByCountry,
        title: "Staff Composition: Country Wise",
        // For navigation pop-up
        id: "staff-composition-country-wise",
        page: "/operations/people-and-culture"
    },
    [componentName.staffByDepartment]: {
        name: componentName.staffByDepartment,
        title: "Staff Composition: Department Wise",
        // For navigation pop-up
        id: "staff-composition-department-wise",
        page: "/operations/people-and-culture"
    },
    [componentName.staffByAge]: {
        name: componentName.staffByAge,
        title: "Staff Composition: Age Wise",
        // For navigation pop-up
        id: "staff-composition-age-wise",
        page: "/operations/people-and-culture"
    },
    [componentName.staffByGender]: {
        name: componentName.staffByGender,
        title: "Staff Composition: Gender Wise",
        // For navigation pop-up
        id: "staff-composition-gender-wise",
        page: "/operations/people-and-culture"
    },
    // Operations -> People And Culture ☝

    // Operations -> People And Culture ->  Turnover Trends 👇
    [componentName.turnoverRate]: {
        name: componentName.turnoverRate,
        title: "Turnover Rate",
        id: "turnover-rate",
        page: "/operations/people-and-culture/turnover-trends"
    },
    [componentName.turnoverType]: {
        name: componentName.turnoverType,
        title: "Turnover Exit Type",
        id: "turnover-type",
        page: "/operations/people-and-culture/turnover-trends"
    },
    [componentName.turnoverDuration]: {
        name: componentName.turnoverDuration,
        title: "Turnover Tenure",
        id: "turnover-tenure",
        page: "/operations/people-and-culture/turnover-trends"
    },
    [componentName.turnoverDepartment]: {
        name: componentName.turnoverDepartment,
        title: "Department Wise Turnover Data",
        id: "turnover-department",
        page: "/operations/people-and-culture/turnover-trends"
    },
    [componentName.turnoverPerformance]: {
        name: componentName.turnoverPerformance,
        title: "Performance Data & Turnover Types",
        id: "turnover-performance",
        page: "/operations/people-and-culture/turnover-trends"
    },
    [componentName.probation]: {
        name: componentName.probation,
        title: "Probation Overview",
        id: "probation-overview",
        page: "/operations/people-and-culture/turnover-trends"
    },
    // Operations -> People And Culture ->  Turnover Trends ☝

    // Operations -> People And Culture ->  Learning And Development 👇
    [componentName.training]: {
        name: componentName.training,
        title: "Training Reports",
        id: "training-reports",
        page: "/operations/people-and-culture/learning-and-development"
    },
    [componentName.brokerAccreditation]: {
        name: componentName.brokerAccreditation,
        title: "Broking Enrollments Report",
        id: "broker-accreditation",
        page: "/operations/people-and-culture/learning-and-development"
    },
    [componentName.goodReadsBonus]: {
        name: componentName.goodReadsBonus,
        title: "Good Reads Bonus",
        id: "good-reads-bonus",
        page: "/operations/people-and-culture/learning-and-development"
    },
    // Operations -> People And Culture ->  Learning And Development ☝

    // Operations -> People And Culture ->  Engagement Reports 👇
    [componentName.employeeAssistance]: {
        name: componentName.employeeAssistance,
        title: "Employee Assistance Program",
        id: "employee-assistance-program",
        page: "/operations/people-and-culture/engagement-reports"
    },
    [componentName.engagementScore]: {
        name: componentName.engagementScore,
        title: "Engagement Score",
        id: "engagement-score",
        page: "/operations/people-and-culture/engagement-reports"
    },
    [componentName.onboarding]: {
        name: componentName.onboarding,
        title: "Onboarding",
        id: "onboarding",
        page: "/operations/people-and-culture/engagement-reports"
    },
    [componentName.parentalBonus]: {
        name: componentName.parentalBonus,
        title: "Parental Bonus",
        tooltip: "Parental count is done based on start date of leave",
        id: "parental-bonus",
        page: "/operations/people-and-culture/engagement-reports"
    },
    [componentName.rewards]: {
        name: componentName.rewards,
        title: "Rewards and Recognition",
        tooltip: "The average number of staff is used for yearly and quarterly data.",
        id: "rewards-and-recognition",
        page: "/operations/people-and-culture/engagement-reports"
    },
    [componentName.semiAnnualEngagement]: {
        name: componentName.semiAnnualEngagement,
        title: "Semi Annual Engagement",
        id: "semi-annual-engagement",
        page: "/operations/people-and-culture/engagement-reports"
    },
    [componentName.weddingGifts]: {
        name: componentName.weddingGifts,
        title: "Wedding Gifts",
        id: "wedding-gifts",
        page: "/operations/people-and-culture/engagement-reports"
    },
    [componentName.workAnniversary]: {
        name: componentName.workAnniversary,
        title: "Work Anniversary",
        id: "work-anniversary",
        page: "/operations/people-and-culture/engagement-reports"
    },

    // Operations -> People And Culture ->  Engagement Reports ☝

    // Operations -> Call Analysis 👇
    peakHourCall: {
        name: componentName.peakHourCall,
        title: "Peak Hour Call Analysis",
        tooltip: `X-axis represents the days of the week and y-axis represents hours in a day. Each cell value is the average number of calls received 
        on the particular hour of a weekday.  This heatmap gets affected by selection of date range, so we suggest to select last 3 or 6 months to
        avoid non-uniform weekday counts.`,
        // For navigation pop-up 👇
        id: "peak-hour-call",
        page: "/operations/call-analysis"
    },
    incomingVsMissed: {
        name: componentName.peakHourCall,
        title: "Incoming Vs Missed Calls",
        tooltip: `X-axis represents hours in a day and y-axis represents number of calls in that hour.
        The value is the total number of connected calls and total number of missed calls.`,
        id: "incoming-vs-missed",
        page: "/operations/call-analysis"
    },
    brokerAvailability: {
        name: componentName.brokerAvailability,
        title: "Agent Availability Summary",
        tooltip: `The labels represent the categories of online status. X axis represents hours and Y-axis represents agent name. 
        Value is the total hours in the particular status.`,
        id: "agent-availability",
        page: "/operations/call-analysis"
    },
    agentCallInfo: {
        name: componentName.agentCallInfo,
        title: "Agent Call Summary",
        tooltip: `The labels represent the categories of call. X axis represents number of calls and Y-axis represents agent name.
        Value is the category of call.`,
        id: "agent-call-summary",
        page: "/operations/call-analysis"
    },
    // Operations -> Call Analysis ☝

    // Operations -> Chat Analysis 👇
    peakChatData: {
        name: componentName.peakChatData,
        title: "Peak Hour Chat Analysis",
        tooltip: `X-axis could be hours of the day or page or country or city or region and y-axis could be hours in a day or days of the week 
        based on filter selection. Each cell value is the average number of chats generated on the particular hour of a weekday. 
        This heatmap gets affected by selection of date range, so we suggest to select last 3 or 6 months to avoid non-uniform weekday counts.`,
        // For navigation pop-up 👇
        id: "peak-hour-chat",
        page: "/operations/chat-analysis"
    },
    agentChatData: {
        name: componentName.agentChatData,
        title: "Agent Chat Summary",
        tooltip: `Table view for Agent Chat summary report includes Serial Number, Agent Name, Started By, Chat Missed, Chat Served, 
        Total Chat and Chat Duration. Started By tells us whether the chat was initiated by automatic chat popout (trigger) or initiated 
        by visitors themselves. Chat Duration means total time spent by agents in chats. The chart view of the very report shows agent 
        and their respective chat missed and chat served numbers.`,
        id: "agent-chat-summary",
        page: "/operations/chat-analysis"
    },
    agentChatStatus: {
        name: componentName.agentChatStatus,
        title: "Agent Chat Duration Status",
        tooltip: `Table view of Agent Chat Status Report includes Serial Number, Agent Name and the duration in 4 different statuses
        (Online, Offline, Invisible and Away) for selected date range.  The chart view of the very report shows agent and their respective 
        duration in each 4 statuses.`,
        id: "agent-chat-status",
        page: "/operations/chat-analysis"
    },
    agentOnlineStatus: {
        name: componentName.agentOnlineStatus,
        title: "Agent Online Status",
        tooltip: `Agent Online Status shows exact duration that agent was in the online status for the selected date.`,
        id: "agent-online-status",
        page: "/operations/chat-analysis"
    },
    agentChatSchedule: {
        name: componentName.agentChatSchedule,
        title: "Agent Chat Schedule",
        tooltip: `Agent Chat Schedule shows respective agent schedule for the day and their online duration during the allocated schedule.`,
        id: "agent-chat-schedule",
        page: "/operations/chat-analysis"
    },
    chatDetails: {
        name: componentName.chatDetails,
        title: "Chat Location Details",
        tooltip: `Table view of chat location report shows the region, city and country from which a particular web path generated chat. 
        The chart view  shows country and their respective chat metrics.`,
        id: "chat-location-details",
        page: "/operations/chat-analysis"
    },
    // Operations -> Chat Analysis ☝

    // Sankey 👇
    sankey: {
        name: componentName.sankey,
        title: "Sankey Diagram",
        // For navigation pop-up 👇
        id: "sankey-diagram",
        page: "/sankey"
    },
    safSentDropoffStats: {
        name: componentName.safSentDropoffStats,
        title: "SAF Sent Dropoff Stats",
        id: "saf-sent-dropoff-stats",
        page: "/sankey"
    },
    sankeyStats: {
        name: componentName.sankeyStats,
        title: "Sankey Stats"
    },
    summaryTable: {
        name: componentName.sankey,
        title: "Summary Table",
        id: "summary-table",
        page: "/sankey"
    }
    // Sankey ☝
};

export const tab = {
    // Company Overview
    "/": "Company Overview",

    // Mortgage Broking
    "/mortgage": "Mortgage Broking Overview",
    "/mortgage/overview": "Overview",
    "/mortgage/discharge": "Discharge",
    "/mortgage/marketing": "Marketing Reports",
    "/mortgage/email-marketing": "Mortgage Broking Email Marketing",
    "/mortgage/reviews-and-ratings": "Mortgage Broking Review And Rating",
    "/mortgage/ldas-reports": "Mortgage Broking LDAS Reports",
    "/mortgage/activity-reports": "Activity Reports",
    "/mortgage/sales-reports": "Sales Reports",
    "/mortgage/workload-reports": "Workload Reports",
    "/mortgage/broker-reports": "Broker Reports",
    "/mortgage/lead-rating-report": "Lead Rating Report",

    // Outsourcing
    "/outsourcing": "Outsourcing",

    // Operations
    "/operations": "Operations",
    "/operations/technology": "Technology",
    "/operations/learning-hub": "Learning Hub",
    "/operations/people-and-culture": "People And Culture",
    "/operations/people-and-culture/turnover-trends": "Turnover Trends",
    "/operations/people-and-culture/learning-and-development": "Learning And Development",
    "/operations/people-and-culture/engagement-reports": "Engagement Reports",
    "/operations/call-analysis": "Call Analysis",
    "/operations/chat-analysis": "Chat Analysis",

    // File Upload
    "/upload": "File Upload",

    // Sankey
    "/sankey": "Segment Analysis",

    // Forms
    "/forms": "Forms",
    "/forms/discharge": "Discharge",
    "/forms/discharge/create": "Create Discharge",
    "/forms/data-verification": "Data Verification"
};

export const sortBy = {
    weeklyData: [
        { id: "key", desc: false },
        { id: "dateFrom", desc: false },
        { id: "dateTo", desc: false },
        { id: "week number", desc: false },
        { id: "Lead", desc: false },
        { id: "Target Line-595", desc: false },
        { id: "SAF Sent", desc: false },
        { id: "Submissions", desc: false },
        { id: "HBI Signup", desc: false },
        { id: "Target Line-127", desc: false },
        { id: "SAF Received", desc: false },
        { id: "Target Line-81", desc: false },
        { id: "Target Line-57", desc: false }
    ],
    dailyWeeklyBrokerReport: [
        { id: "broker", desc: false },
        { id: "Phone Enquiry", desc: false },
        { id: "outgoing", desc: false },
        { id: "incoming", desc: false },
        { id: "missed", desc: false }
    ],
    dailyWeeklyISReport: [
        { id: "insideSales", desc: false },
        { id: "Phone Enquiry", desc: false },
        { id: "outgoing", desc: false },
        { id: "incoming", desc: false },
        { id: "missed", desc: false }
    ],
    leadOpportunityReport: [
        { id: "sn", desc: false },
        { id: "dateFrom", desc: false },
        { id: "dateTo", desc: false },
        { id: "weekNumber", desc: false },
        { id: "ldas", desc: false },
        { id: "allCalls", desc: false },
        { id: "callTaken", desc: false },
        { id: "missedCall", desc: false },
        { id: "allChats", desc: false },
        { id: "missedChat", desc: false },
        { id: "totalOpportunities", desc: false }
    ]
};

export const leadOpportunityConstant = {
    ldas: "LDAS",
    allCalls: "All Calls",
    callTaken: "Call Taken",
    missedCall: "Missed Call",
    allChats: "All Chats",
    chatTaken: "Chat Taken",
    missedChat: "Missed Chat",
    totalOpportunities: "Total Opportunities"
};
