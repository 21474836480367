import { cloneDeep } from "lodash";

import { leadOpportunityConstant } from "../constants";
import { Broker, InsideSales } from "../constants/mortgageBroking/brokerInsideSalesTeam";

export const mapWeeklyTableData = (mortgage) => {
    const key = "week number";

    const weeklyData = cloneDeep(mortgage?.mortgageOverview?.weeklyData?.data || []);

    const arrayUniqueByKey = [
        ...new Map(
            weeklyData?.map(item => [item[key], item])
        ).values()
    ];

    const mortgageWeeklyData = weeklyData?.length ? [...weeklyData] : [];

    mortgageWeeklyData.forEach((element) => {
        const objIndex = arrayUniqueByKey.findIndex((obj => obj["week number"] === element["week number"]));
        arrayUniqueByKey[objIndex]["key"] = objIndex+1;
        arrayUniqueByKey[objIndex]["dateFrom"] = element.from;
        arrayUniqueByKey[objIndex]["dateTo"] = element.to;
        arrayUniqueByKey[objIndex][element.category] = element.value ? element.value : "-";
        arrayUniqueByKey[objIndex]["Target Line-595"] = 595;
        arrayUniqueByKey[objIndex]["Target Line-127"] = 127;
        arrayUniqueByKey[objIndex]["Target Line-81"] = 81;
        arrayUniqueByKey[objIndex]["Target Line-57"] = 57;
    });
    return arrayUniqueByKey;
};

export const mapBrokerIsTeamTableData = (data, teamName="broker") => {
    const teamMembers = teamName === "broker" ? Broker : InsideSales;
    const valueToMap = {
        cancelled: 0,
        incoming: 0,
        missed: 0,
        "Phone Enquiry": 0,
        outgoing: 0
    };

    const res = [];
    teamMembers.forEach((member) => {
        const memberData = data.data?.[member] || {}; // { cancelled: 0, incoming: 1, missed: 2, outgoing: 3 }
        const sum = Object.values(memberData).reduce((acc, curr) => acc + curr, 0);
        if(sum !== 0)
            res.push({
                ...valueToMap,
                ...memberData,
                [teamName]: member // broker: "Anil Joshi"
            });
    });
    return res;
};

export const mapLeadOpportunityTableData = (data) => {
    var updatedData=[];
    if(typeof data === "undefined") {
        return data;
    }
    data.forEach((element, index) => {
        var key=[];
        key["sn"] = index+1;
        key["dateFrom"] = element[0];
        key["dateTo"] = element[1];
        key["weekNumber"] = element[2];
        key["ldas"] = element[3];
        key["allCalls"] = element[4];
        key["callTaken"] = element[5];
        key["missedCall"] = element[6];
        key["allChats"] = element[7];
        key["chatTaken"] = element[8];
        key["missedChat"] = element[9];
        key["totalOpportunities"] = element[10];
        updatedData.push(key);
    });
    return updatedData;
};

export const mapLeadOpportunityMultiLineData = (data) => {
    var updatedData=[];
    if(typeof data === "undefined") {
        return data;
    }
    const catergories = ["ldas", "allCalls", "allChats", "totalOpportunities"];
    data.forEach((element) => {
        catergories.forEach((cat) => {
            const obj = {
                "dateFrom": element["dateFrom"],
                "dateTo": element["dateTo"],
                "week number": element["weekNumber"],
                "actual week number": element["actualWeekNumber"], // for comparison chart, week number displayed in x-axis and actual week number are different
                "category": leadOpportunityConstant[cat],
                "value": element[cat]
            };
            updatedData.push(obj);
        });
    });
    return updatedData;
};
