import produce from "immer";

import { componentName } from "../../constants";

export const initialState = {
    agentChatData: {},
    agentChatStatus: {},
    peakChatData: {},
    chatDetails: {},
    agentOnlineStatus: {},
    [componentName.agentChatSchedule]: {}
};

export const CHAT_ANALYSIS_ACTION_TYPES = Object.freeze({
    FETCHING_AGENT_CHAT_DATA: "FETCHING_AGENT_CHAT_DATA",
    FETCHING_AGENT_CHAT_STATUS: "FETCHING_AGENT_CHAT_STATUS",
    FETCHING_PEAK_CHAT_DATA: "FETCHING_PEAK_CHAT_DATA",
    FETCHING_CHAT_DETAILS_DATA: "FETCHING_CHAT_DETAILS_DATA",
    FETCHING_AGENT_ONLINE_STATUS_DATA: "FETCHING_AGENT_ONLINE_STATUS_DATA",
    FETCHING_AGENT_CHAT_SCHEDULE: "FETCHING_AGENT_CHAT_SCHEDULE",
    FETCHING_AGENT_CHAT_SCHEDULE_ONLINE_DATA: "FETCHING_AGENT_CHAT_SCHEDULE_ONLINE_DATA",

    CLEAR_AGENT_CHAT_DATA: "CLEAR_AGENT_CHAT_DATA",
    CLEAR_AGENT_CHAT_STATUS: "CLEAR_AGENT_CHAT_STATUS",
    CLEAR_PEAK_CHAT_DATA: "CLEAR_PEAK_CHAT_DATA",
    CLEAR_CHAT_DETAILS_DATA: "CLEAR_CHAT_DETAILS_DATA",
    CLEAR_AGENT_ONLINE_STATUS_DATA: "CLEAR_AGENT_ONLINE_STATUS_DATA",
    CLEAR_AGENT_CHAT_SCHEDULE: "CLEAR_AGENT_CHAT_SCHEDULE",
    CLEAR_AGENT_CHAT_SCHEDULE_ONLINE_DATA: "CLEAR_AGENT_CHAT_SCHEDULE_ONLINE_DATA"
});

export const chatAnalysisReducer = produce((draft, action) => {
    switch(action.type) {
        // Agent Chat Data
        case CHAT_ANALYSIS_ACTION_TYPES.FETCHING_AGENT_CHAT_DATA:
            draft.agentChatData = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case CHAT_ANALYSIS_ACTION_TYPES.CLEAR_AGENT_CHAT_DATA:
            draft.agentChatData = null;
            return draft;

        // Agent Chat Status
        case CHAT_ANALYSIS_ACTION_TYPES.FETCHING_AGENT_CHAT_STATUS:
            draft.agentChatStatus = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case CHAT_ANALYSIS_ACTION_TYPES.CLEAR_AGENT_CHAT_STATUS:
            draft.agentChatStatus = null;
            return draft;

        // Peak Chat Data
        case CHAT_ANALYSIS_ACTION_TYPES.FETCHING_PEAK_CHAT_DATA:
            draft.peakChatData = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case CHAT_ANALYSIS_ACTION_TYPES.CLEAR_PEAK_CHAT_DATA:
            draft.peakChatData = null;
            return draft;

        // Chat Location Details
        case CHAT_ANALYSIS_ACTION_TYPES.FETCHING_CHAT_DETAILS_DATA:
            draft.chatDetails = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case CHAT_ANALYSIS_ACTION_TYPES.CLEAR_CHAT_DETAILS_DATA:
            draft.chatDetails = null;
            return draft;

        // Agent Online Status
        case CHAT_ANALYSIS_ACTION_TYPES.FETCHING_AGENT_ONLINE_STATUS_DATA:
            draft.agentOnlineStatus = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case CHAT_ANALYSIS_ACTION_TYPES.CLEAR_AGENT_ONLINE_STATUS_DATA:
            draft.agentOnlineStatus = null;
            return draft;

        // Agent Schedule
        case CHAT_ANALYSIS_ACTION_TYPES.FETCHING_AGENT_CHAT_SCHEDULE:
            draft[componentName.agentChatSchedule] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case CHAT_ANALYSIS_ACTION_TYPES.CLEAR_AGENT_CHAT_SCHEDULE:
            draft[componentName.agentChatSchedule] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
