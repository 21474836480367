import produce from "immer";

export const TV_MODE = "tvMode";

const initialState = {
    [TV_MODE]: false
};

export const ACTIONS_TYPES = Object.freeze({
    SET_MODE: "SET_MODE",
    CLEAR_MODE: "CLEAR_MODE"
});

export const modeReducer = produce((draft, action) => {
    const { type } = action;

    switch(type) {
        case ACTIONS_TYPES.SET_MODE:
            draft[action.payload.mode] = true;
            return draft;

        case ACTIONS_TYPES.CLEAR_MODE:
            draft[action.payload.mode] = false;
            return draft;

        default:
            return draft;
    }
}, initialState);
