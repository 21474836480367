import produce from "immer";

import { componentName } from "../../../constants";

export const initialState = {
    [componentName.training]: {},
    [componentName.brokerAccreditation]: {},
    [componentName.goodReadsBonus]: {}
};

export const L_AND_D_ACTION_TYPES = Object.freeze({
    FETCH_TRAINING_DATA: "FETCH_TRAINING_DATA",
    FETCH_BROKER_ACCREDITATION_DATA: "FETCH_BROKER_ACCREDITATION_DATA",
    FETCH_GOOD_READS_BONUS: "FETCH_GOOD_READS_BONUS",

    CLEAR_TRAINING_DATA: "CLEAR_TRAINING_DATA",
    CLEAR_BROKER_ACCREDITATION_DATA: "CLEAR_BROKER_ACCREDITATION_DATA",
    CLEAR_GOOD_READS_BONUS: "CLEAR_GOOD_READS_BONUS"
});

export const learningAndDevelopmentReducer = produce((draft, action) => {
    switch(action.type) {
        // Training Data
        case L_AND_D_ACTION_TYPES.FETCH_TRAINING_DATA:
            draft[componentName.training] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case L_AND_D_ACTION_TYPES.CLEAR_TRAINING_DATA:
            draft[componentName.training] = null;
            return draft;

        // Broker Accreditation Data
        case L_AND_D_ACTION_TYPES.FETCH_BROKER_ACCREDITATION_DATA:
            draft[componentName.brokerAccreditation] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case L_AND_D_ACTION_TYPES.CLEAR_BROKER_ACCREDITATION_DATA:
            draft[componentName.brokerAccreditation] = null;
            return draft;

        // Good Reads Bonus
        case L_AND_D_ACTION_TYPES.FETCH_GOOD_READS_BONUS:
            draft[componentName.goodReadsBonus] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case L_AND_D_ACTION_TYPES.CLEAR_GOOD_READS_BONUS:
            draft[componentName.goodReadsBonus] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
