import produce from "immer";

const initialState = {
    isLoading: false
};

export const ACTIONS_TYPES = Object.freeze({
    OPEN_LOADER: "OPEN_LOADER",
    CLOSE_LOADER: "CLOSE_LOADER"
});


export const loaderReducer = produce((draft, action) => {
    const { type } = action;
    switch(type) {
        case "OPEN_LOADER":
            draft.isLoading = true;
            draft[action.payload] = true;
            return draft;

        case "CLOSE_LOADER":
            draft.isLoading = false;
            draft[action.payload] = false;
            return draft;

        default:
            return draft;
    }
}, initialState);
