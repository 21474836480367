import { componentName } from ".";

export const TOAST_DURATION = 3000;

export const toasterMessage = {
    // Company Overview
    leadOpportunityReport: "Successfully retrieved lead opportunity data",
    notContactData: "Successfully retrieved not contacted leads data",
    dailyWeeklyBrokerReport: "Successfully retrieved Phone Enquiry Audit data",
    insideSalesWorkload: "Successfully retrieved Inside sales workload data",
    dailyWeeklyISReport: "Successfully retrieved Phone enquiry for Inside sales data",
    pipelineReport: "Successfully retrieved pipeline report data",
    [componentName.webLeads]: "Successfully retrieved web leads report data",
    [componentName.leadConversion]: "Successfully retrieved leads conversion report data",
    [componentName.leadRatingReport]: "Successfully retrieved lead rating report data",

    // Mortgage Broking -> Overview
    leadEnquiries: "Successfully retrieved lead enquiries data",
    leadMileStone: "Successfully retrieved from discharge data",
    weeklyData: "Successfully retrieved weekly data",
    netLoanBook: "Successfully retrieved loan book data",
    topLenders: "Successfully retrieved data from top lenders submission and settlement",
    arrearsRate: "Successfully retrieved data from arrears rate",
    arrearsRunOff: "Successfully retrieved data from arrears run off",
    referralSettled: "Successfully retrieved Repeat, Referral and Self generated business in number Settled",
    referralSubmission: "Successfully retrieved Repeat, Referral and Self generated business in number Submission",
    googleAnalyticsVisitor: "Successfully retrieved google analytics visitor data",

    // Mortgage Broking -> Broker Reports
    [componentName.loanBook]: "Successfully retrieved loan book data",
    [componentName.leadQuality]: "Successfully retrieved lead quality data",

    // Mortgage Broking -> Discharge
    retention: "Successfully retrieved customer retention data",
    brokerRetention: "Successfully retrieved customer retention by broker",
    lenderRetention: "Successfully retrieved customer retention by lender",

    // Mortgage Broking -> Email Marketing
    newsLetterRba: "Successfully retrieved data from news letter Rba",
    newsLetterEMag: "Successfully retrieved data from news letter EMag",
    newsLetterConnective: "Successfully retrieved data from news letter Connective",

    // Mortgage Broking -> Review And Rating
    customerRating: "Successfully retrieved data from customer rating",
    internalRating: "Successfully retrieved data from internal rating",
    googleReviewRating: "Successfully retrieved data from google review rating",
    productReviewRating: "Successfully retrieved data from product review rating",
    [componentName.brokers]: "Successfully retrieved broker list",
    [componentName.supports]: "Successfully retrieved support list",
    [componentName.brokerRating]: "Successfully retrieved broker rating data",
    [componentName.treePlantation]: "Successfully retrieved tree plantation data",
    [componentName.leadNps]: "Successfully retrieved lead nps data",
    [componentName.leadFeedback]: "Successfully retrieved lead feedback data",

    // Mortgage Broking -> Ldas Reports
    ldasReport: "Successfully retrieved data from lead distribution report",
    refinanceAndPurchase: "Successfully retrieved refinance and purchase data",
    ldasBrokerYearly: "Successfully retrieved LDAS broker yearly data",
    ldasIsYearly: "Successfully retrieved LDAS inside sales yearly data",
    [componentName.pageSegment]: "Successfully retrieced Page Segment Data",

    // Mortgage Broking -> Activity Reports
    [componentName.weeklyBrokerScore]: "Successfully retrieved activity score data",

    // Mortgage Broking -> Sales Reports
    [componentName.monthlySales]: "Successfully retrieved monthly sales data",

    // Mortgage Broking -> Workload Reports
    [componentName.lpsWorkloads]: "Successfully retrieved LPS workloads data",
    [componentName.caWorkloads]: "Successfully retrieved CA workloads data",
    [componentName.crWorkloads]: "Successfully retrieved CR workloads data",
    [componentName.dataProcessingWorkloads]: "Successfully retrieved data processing workloads data",
    [componentName.outsourcingWorkloads]: "Successfully retrieved outsourcing workloads data",

    // Outsourcing
    outsourcingStaff: "Successfully retrieved data from outsourcing staff",
    outsourcingRevenue: "Successfully retrieved data from outsourcing revenue",
    outsourcingLps: "Successfully retrieved data from outsourcing lps",
    outsourcingCa: "Successfully retrieved data from outsourcing ca",

    // Operations -> Overview
    staffAllocation: "Successfully retrieved data from staff allocation",
    recruiterNps: "Successfully retrieved data from recruiter NPS",

    // Operations -> Learning Hub
    learningHub: "Successfully retrieved data for learning hub",

    // Operations -> People And Culture
    [componentName.staffByYear]: "Successfully retrieved staff composition data by year",
    [componentName.staffByCountry]: "Successfully retrieved staff composition data by country",
    [componentName.staffByDepartment]: "Successfully retrieved staff composition data by department",
    [componentName.staffByAge]: "Successfully retrieved staff composition data by age",
    [componentName.staffByGender]: "Successfully retrieved staff composition data by gender",

    // Operations -> People And Culture -> Turnover Trends
    [componentName.turnoverRate]: "Successfully retrieved turnover trend data",
    [componentName.turnoverDepartment]: "Successfully retrieved turnover department data",
    [componentName.turnoverDuration]: "Successfully retrieved turnover tenure data",
    [componentName.turnoverPerformance]: "Successfully retrieved turnover performace data",
    [componentName.turnoverType]: "Successfully retrieved turnover type data",
    [componentName.probation]: "Successfully retrieved probation data",

    // Operations -> People And Culture -> Learning And Development
    [componentName.training]: "Successfully retrieved training data",
    [componentName.brokerAccreditation]: "Successfully retrieved broker accreditation data",
    [componentName.goodReadsBonus]: "Successfully retrieved good reads bonus data",

    // Operations -> People And Culture -> Engagement Reports
    [componentName.employeeAssistance]: "Successfully retrieved employee assistance data",
    [componentName.engagementScore]: "Successfully retrieved engagement score data",
    [componentName.onboarding]: "Successfully retrieved onboarding data",
    [componentName.parentalBonus]: "Successfully retrieved parental bonus data",
    [componentName.rewards]: "Successfully retrieved rewards data",
    [componentName.semiAnnualEngagement]: "Successfully retrieved semi annual engagement data",
    [componentName.workAnniversary]: "Successfully retrieved work anniversary data",

    // Operations -> Call Analysis
    peakHourCall: "Successfully retrieved peak hour call data",
    brokerAvailability: "Successfully retrieved broker availability data",
    agentCallInfo: "Successfully retrieved agent call data",

    // Operations -> Chat Analysis
    agentChatData: "Successfully retrieved agent chat data",
    agentChatStatus: "Successfully retrieved agent chat status data",
    peakChatData: "Successfully retrieved peak hour chat data",
    chatDetails: "Successfully retrieved chat details data",
    agentOnlineStatus: "Successfully retrieved agent online status data",
    [componentName.agentChatSchedule]: "Successfully retrieved agent chat schedule",

    // Sankey
    sankey: "Successfully retrieved sankey data",
    sankeyStats: "Successfully retrieved sankey stats data",
    safSentDropoffStats: "Successfully retrieved saf sent dropoff stats data",

    // Forms
    [componentName.discharge]: "Successfully retrieved discharge data",
    [componentName.dischargeBrokers]: "Successfully retrieved discharge brokers data",
    [componentName.dischargeLenders]: "Successfully retrieved discharge lenders data",
    [componentName.dischargeLps]: "Successfully retrieved discharge LPS data",

    // Forms -> Data Verification
    [componentName.reports]: "Successfully retrieved reports list"
};

export const toasterErrorMessage = {

    // Company Overview
    dailyWeeklyBrokerReport: "Error retrieving phone enquiry audit data",
    notContactData: "Error retrieving not contacted leads data",
    dailyWeeklyISReport: "Error retrieving IS Workload data",
    insideSalesWorkload: "Error retrieving weekly lead indicator data",
    leadOpportunityReport: "Error retrieving lead opportunity data",
    pipelineReport: "Error retrieving pipeline report data",
    [componentName.webLeads]: "Error retrieving web leads report data",
    [componentName.leadConversion]: "Error retrieving lead conversion report data",
    [componentName.leadRatingReport]: "Error retrieving lead rating report data",

    // Mortgage Broking -> Overview
    leadEnquiries: "Error retrieving lead enquiries data",
    leadMileStone: "Error retrieving discharge data",
    weeklyData: "Error retrieving weekly data",
    netLoanBook: "Error retrieving loan book data",
    topLenders: "Error retrieving top lenders submission and settlement data",
    arrearsRate: "Error retrieving rate data",
    arrearsRunOff: "Error retrieving Run off data",
    referralSettled: "Error retrieving Repeat, Referral and Self generated business in number Settled",
    referralSubmission: "Error retrieving Repeat, Referral and Self generated business in number Submission",
    googleAnalyticsVisitor: "Error retrieving google analytics visitor data",

    // Mortgage Broking -> Broker Reports
    [componentName.loanBook]: "Error retrieving loan book data",
    [componentName.leadQuality]: "Error retrieving lead quality data",

    // Mortgage Broking -> Discharge
    retention: "Error retrieving customer retention data",
    brokerRetention: "Error retrieving customer retention by broker",
    lenderRetention: "Error retrieving customer retention by lender",

    // Mortgage Broking -> Email Marketing
    newsLetterRba: "Error retrieving news letter Rba data",
    newsLetterEMag: "Error retrieving news letter EMag data",
    newsLetterConnective: "Error retrieving news letter Connective data",

    // Mortgage Broking -> Review And Rating
    customerRating: "Error retrieving customer rating data",
    internalRating: "Error retrieving internal rating data",
    googleReviewRating: "Error retrieving google review rating data",
    productReviewRating: "Error retrieving product review rating data",
    [componentName.brokers]: "Error retrieving broker list",
    [componentName.supports]: "Error retrieving support list",
    [componentName.brokerRating]: "Error retrieving broker rating data",
    [componentName.treePlantation]: "Error retrieving tree plantation data",
    [componentName.leadNps]: "Error retrieving lead nps data",
    [componentName.leadFeedback]: "Error retrieving lead feedback data",

    // Mortgage Broking -> Ldas Reports
    ldasReport: "Error retrieving lead distribution data",
    refinanceAndPurchase: "Error retrieving refinance and purchase data",
    ldasBrokerYearly: "Error retrieving LDAS broker yearly data",
    ldasIsYearly: "Error retrieving LDAS inside sales yearly data",
    [componentName.pageSegment]: "Error retrieving Page Segment Data",

    // Mortgage Broking -> Activity Reports
    [componentName.weeklyBrokerScore]: "Error retrieving activity score data",

    // Mortgage Broking -> Sales Reports
    [componentName.monthlySales]: "Error retrieving monthly sales data",

    // Mortgage Broking -> Workload Reports
    [componentName.lpsWorkloads]: "Error retrieving LPS workloads data",
    [componentName.caWorkloads]: "Error retrieving CA workloads data",
    [componentName.crWorkloads]: "Error retrieving CR workloads data",
    [componentName.dataProcessingWorkloads]: "Error retrieving data processing workloads data",
    [componentName.outsourcingWorkloads]: "Error retrieving outsourcing workloads data",

    // Outsourcing
    outsourcingStaff: "Error retrieving outsourcing staff data",
    outsourcingRevenue: "Error retrieving outsourcing revenue data",
    outsourcingLps: "Error retrieving outsourcing LPS data",
    outsourcingCa: "Error retrieving outsourcing CA data",

    // Operations -> Overview
    staffAllocation: "Error retrieving staff allocation data",
    recruiterNps: "Error retrieving recruiter NPS data",

    // Operations -> People And Culture
    [componentName.staffByYear]: "Error retrieving staff composition data by year",
    [componentName.staffByCountry]: "Error retrieving staff composition data by country",
    [componentName.staffByDepartment]: "Error retrieving staff composition data by department",
    [componentName.staffByAge]: "Error retrieving staff composition data by age",
    [componentName.staffByGender]: "Error retrieving staff composition data by gender",

    // Operations -> People And Culture -> Turnover Trends
    [componentName.turnoverRate]: "Error retrieving turnover trend data",
    [componentName.turnoverDepartment]: "Error retrieving turnover department data",
    [componentName.turnoverDuration]: "Error retrieving turnover tenure data",
    [componentName.turnoverPerformance]: "Error retrieving turnover performace data",
    [componentName.turnoverType]: "Error retrieving turnover type data",
    [componentName.probation]: "Error retrieving probation data",

    // Operations -> People And Culture -> Learning And Development
    [componentName.training]: "Error retrieving training data",
    [componentName.brokerAccreditation]: "Error retrieving broker accreditation data",
    [componentName.goodReadsBonus]: "Error retrieving good reads bonus data",

    // Operations -> People And Culture -> Engagement Reports
    [componentName.employeeAssistance]: "Error retrieving employee assistance data",
    [componentName.engagementScore]: "Error retrieving engagement score data",
    [componentName.onboarding]: "Error retrieving onboarding data",
    [componentName.parentalBonus]: "Error retrieving parental bonus data",
    [componentName.rewards]: "Error retrieving rewards data",
    [componentName.semiAnnualEngagement]: "Error retrieving semi annual engagement data",
    [componentName.workAnniversary]: "Error retrieving work anniversary data",

    // Operations -> Learning Hub
    learningHub: "Error retrieving data for learning hub",

    // Operations -> Call Analysis
    peakHourCall: "Error retrieving peak hour call data",
    brokerAvailability: "Error retrieving broker availability data",
    agentCallInfo: "Error retrieving agent call data",

    // Operations -> Chat Analysis
    agentChatData: "Error retrieving agent chat data",
    agentChatStatus: "Error retrieving agent chat status data",
    peakChatData: "Error retrieving peak hour chat data",
    chatDetails: "Error retrieving chat details data",
    agentOnlineStatus: "Error retrieving agent online status data",
    [componentName.agentChatSchedule]: "Error retrieving agent chat schedule data",

    // Sankey
    sankey: "Error retrieving sankey data",
    sankeyStats: "Error retrieving sankey stats data",
    safSentDropoffStats: "Error retrieving saf sent dropoff stats data",

    // Forms
    [componentName.discharge]: "Error retrieving discharge data",
    [componentName.dischargeBrokers]: "Error retrieving discharge brokers data",
    [componentName.dischargeLenders]: "Error retrieving discharge lenders data",
    [componentName.dischargeLps]: "Error retrieving discharge LPS data",

    // Forms -> Data Verification
    [componentName.reports]: "Error retrieving reports list"
};
