/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from "classnames";
import { Fragment, useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { setOpenTabs, toggleSidebar } from "../../../actions/sidebarAction";
import { ReactComponent as ArrowForward } from "../../../assets/svg/ArrowForward.svg";
import {
    CALL_ANALYSIS,
    CHAT_REPORT,
    COMPANY_OVERVIEW,
    EMAIL_MARKETING,
    FILE_UPLOAD_EMAILS,
    LDAS_REPORTS,
    MORTGAGE_BROKING,
    OPERATIONS,
    OUTSOURCING,
    OUTSOURCING_EMAILS,
    REVIEW_AND_RATING,
    SANKEY_DIAGRAM,
    UPLOAD_FILE,
    DISCHARGE_FORMS,
    FORMS,
    // DISCHARGE,
    DATA_VERIFICATION,
    LEARNING_HUB,
    LEARNING_HUB_EMAILS,
    ACTIVITY_REPORTS,
    PEOPLE_AND_CULTURE,
    PEOPLE_AND_CULTURE_EMAILS,
    SALES_REPORTS,
    WORKLOAD_REPORTS,
    TURNOVER_TRENDS,
    LEARNING_AND_DEVELOPMENT,
    LEARNING_AND_DEVELOPMENT_EMAILS,
    TURNOVER_TREND_EMAILS,
    TECHNOLOGY,
    ENGAGEMENT_REPORTS,
    BROKER_REPORTS,
    MARKETING,
    LQS_REPORTS,
    MARKETING_REPORTS_EMAILS,
    TECHNOLOGY_REPORTS_EMAILS
} from "../../../constants/sidebar";
import variables from "../../../scss/abstracts/_exports.scss";
import { Logo } from "../Logo";

const SUBMENU_CONTAINERS = [MORTGAGE_BROKING, OPERATIONS, FORMS];

const SIDEBAR_ITEMS = [
    {
        name: COMPANY_OVERVIEW,
        title: "Main",
        link: "/",
        id: "company-overview",
        svgPath: "M0.5 8.59786H7.16667V0.862183H0.5V8.59786ZM0.5 14.7864H7.16667V10.145H0.5V14.7864ZM8.83333 14.7864H15.5V7.05072H8.83333V14.7864ZM8.83333 0.862183V5.50359H15.5V0.862183H8.83333Z",
        fill: "",
        width: 20,
        height: 18.57
    },
    {
        name: MORTGAGE_BROKING,
        title: "Divisions",
        link: "/mortgage",
        id: "mortage-broking",
        svgPath: "M9.83327 9.10032C7.9416 8.64391 7.33327 8.17204 7.33327 7.43715C7.33327 6.59396 8.17493 6.00605 9.58327 6.00605C11.0666 6.00605 11.6166 6.66358 11.6666 7.63054H13.5083C13.4499 6.3 12.5749 5.07777 10.8333 4.68325V2.98914H8.33327V4.66004C6.7166 4.98494 5.4166 5.95964 5.4166 7.45262C5.4166 9.23956 7.00827 10.1292 9.33327 10.6475C11.4166 11.1116 11.8333 11.7923 11.8333 12.5118C11.8333 13.0455 11.4249 13.8964 9.58327 13.8964C7.8666 13.8964 7.1916 13.1848 7.09994 12.2719H5.2666C5.3666 13.9661 6.73327 14.9175 8.33327 15.2347V16.9134H10.8333V15.2502C12.4583 14.964 13.7499 14.0898 13.7499 12.504C13.7499 10.3071 11.7249 9.55672 9.83327 9.10032Z",
        fill: variables["dark-600"],
        width: 20,
        height: 20,
        subtab: [
            {
                name: WORKLOAD_REPORTS,
                link: "/mortgage/workload-reports",
                id: "workloadReports",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: BROKER_REPORTS,
                link: "/mortgage/broker-reports",
                id: "brokerReports",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: SALES_REPORTS,
                link: "/mortgage/sales-reports",
                id: "salesReports",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },

            /**
             * Hiding the Discharge Page
             * The page currently has 3 repots for Customer Retention
             * We will unhide after the imporovements are complete
             */

            // {
            //     name: DISCHARGE,
            //     link: "/mortgage/discharge",
            //     id: "discharge",
            //     fill: variables["dark-600"],
            //     width: 20,
            //     height: 20
            // },
            {
                name: MARKETING,
                link: "/mortgage/marketing",
                id: "marketing",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: EMAIL_MARKETING,
                link: "/mortgage/email-marketing",
                id: "emailMarketing",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: REVIEW_AND_RATING,
                link: "/mortgage/reviews-and-ratings",
                id: "reviewAndRating",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: LDAS_REPORTS,
                link: "/mortgage/ldas-reports",
                id: "ldasReports",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: ACTIVITY_REPORTS,
                link: "/mortgage/activity-reports",
                id: "activityReports",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: LQS_REPORTS,
                link: "/mortgage/lead-rating-report",
                id: "leadRatingReport",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            }
        ],
        sublinks: [
            // "/mortgage/discharge",
            "/mortgage/marketing",
            "/mortgage/email-marketing",
            "/mortgage/reviews-and-ratings",
            "/mortgage/ldas-reports",
            "/mortgage/activity-reports",
            "/mortgage/sales-reports",
            "/mortgage/workload-reports",
            "/mortgage/lead-rating-report"
        ]
    },
    {
        name: OUTSOURCING,
        link: "/outsourcing",
        id: "outSourcing",
        svgPath: "M2.5 7.42874L10 14.3909L15.8333 8.97588V12.5266H17.5V6.33801H10.8333V7.88515H14.6583L10 12.2094L3.675 6.33801L2.5 7.42874Z",
        fill: variables["dark-600"],
        width: 20,
        height: 20
    },
    {
        name: OPERATIONS,
        link: "/operations",
        id: "operations",
        svgPath: "M13.3333 9.30486C14.7166 9.30486 15.8249 8.26828 15.8249 6.98415C15.8249 5.70003 14.7166 4.66345 13.3333 4.66345C11.9499 4.66345 10.8333 5.70003 10.8333 6.98415C10.8333 8.26828 11.9499 9.30486 13.3333 9.30486ZM6.66658 9.30486C8.04992 9.30486 9.15825 8.26828 9.15825 6.98415C9.15825 5.70003 8.04992 4.66345 6.66658 4.66345C5.28325 4.66345 4.16658 5.70003 4.16658 6.98415C4.16658 8.26828 5.28325 9.30486 6.66658 9.30486ZM6.66658 10.852C4.72492 10.852 0.833252 11.7571 0.833252 13.5595V15.4934H12.4999V13.5595C12.4999 11.7571 8.60825 10.852 6.66658 10.852ZM13.3333 10.852C13.0916 10.852 12.8166 10.8675 12.5249 10.8907C13.4916 11.5405 14.1666 12.4146 14.1666 13.5595V15.4934H19.1666V13.5595C19.1666 11.7571 15.2749 10.852 13.3333 10.852Z",
        fill: variables["dark-600"],
        width: 20,
        height: 20,
        subtab: [
            {
                name: TECHNOLOGY,
                link: "/operations/technology",
                id: "technology",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: LEARNING_HUB,
                link: "/operations/learning-hub",
                id: "learning-hub",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: PEOPLE_AND_CULTURE,
                link: "/operations/people-and-culture",
                id: "people-and-culture",
                fill: variables["dark-600"],
                width: 20,
                height: 20,
                subtab: [
                    {
                        name: TURNOVER_TRENDS,
                        link: "/operations/people-and-culture/turnover-trends",
                        id: "turnover-trends",
                        fill: variables["dark-600"]
                    },
                    {
                        name: LEARNING_AND_DEVELOPMENT,
                        link: "/operations/people-and-culture/learning-and-development",
                        id: "learning-and-development",
                        fill: variables["dark-600"]
                    },
                    {
                        name: ENGAGEMENT_REPORTS,
                        link: "/operations/people-and-culture/engagement-reports",
                        id: "engagement-reports",
                        fill: variables["dark-600"]
                    }
                ],
                sublinks: [
                    "/operations/people-and-culture/turnover-trends",
                    "/operations/people-and-culture/learning-and-development",
                    "/operations/people-and-culture/engagement-reports"
                ]
            },
            {
                name: CALL_ANALYSIS,
                link: "/operations/call-analysis",
                id: "call-analysis",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            },
            {
                name: CHAT_REPORT,
                link: "/operations/chat-analysis",
                id: "chat-analysis",
                fill: variables["dark-600"],
                width: 20,
                height: 20
            }
        ],
        sublinks: ["/operations/learning-hub", "/operations/people-and-culture", "/operations/call-analysis", "/operations/chat-analysis", "/operations/people-and-culture/turnover-trends", "/operations/people-and-culture/learning-and-development"]
    },
    {
        name: FORMS,
        title: "Misc",
        link: "/forms/discharge",
        id: "forms",
        svgPath: "M13.3333 13.3333V1.66667H1.66667V13.3333H13.3333ZM13.3333 0C13.7754 0 14.1993 0.175595 14.5118 0.488155C14.8244 0.800716 15 1.22464 15 1.66667V13.3333C15 14.2583 14.25 15 13.3333 15H1.66667C1.22464 15 0.800716 14.8244 0.488155 14.5118C0.175595 14.1993 0 13.7754 0 13.3333V1.66667C0 1.22464 0.175595 0.800716 0.488155 0.488155C0.800716 0.175595 1.22464 0 1.66667 0H13.3333ZM11.4167 5.29167L10.5833 6.125L8.875 4.41667L9.70833 3.58333C9.88333 3.4 10.175 3.4 10.35 3.58333L11.4167 4.65C11.6 4.825 11.6 5.11667 11.4167 5.29167ZM3.33333 9.95L8.38333 4.9L10.1 6.61667L5.05 11.6667H3.33333V9.95Z",
        fill: "",
        width: 20,
        height: 18.57,
        subtab: [
            {
                name: DISCHARGE_FORMS,
                link: "/forms/discharge",
                id: "discharge-forms",
                fill: variables["dark-600"],
                width: 20,
                height: 20,
                sublinks: ["/forms/discharge/create"]
            },
            {
                name: DATA_VERIFICATION,
                link: "/forms/data-verification",
                id: "data-verification",
                fill: variables["dark-600"],
                width: 20,
                height: 20,
                sublinks: []
            }
        ],
        sublinks: ["/forms/discharge", "/forms/discharge/create", "/forms/data-verification"]
    },
    {
        name: SANKEY_DIAGRAM,
        link: "/sankey",
        id: "sankey",
        svgPath: "M4.16675 7.87179H6.66675V15.4527H4.16675V7.87179ZM8.83342 4.6228H11.1667V15.4527H8.83342V4.6228ZM13.5001 10.8113H15.8334V15.4527H13.5001V10.8113Z",
        fill: variables["dark-600"],
        width: 20,
        height: 20
    }
];

const BUTTON_ITEMS = [
    {
        name: UPLOAD_FILE,
        type: "button",
        link: "/upload",
        id: "uploadFile",
        svgPath: "M13.7502 5.04631V14.6296C13.7502 16.4713 12.2585 17.963 10.4168 17.963C8.57516 17.963 7.0835 16.4713 7.0835 14.6296V4.21297C7.0835 3.06297 8.01683 2.12964 9.16683 2.12964C10.3168 2.12964 11.2502 3.06297 11.2502 4.21297V12.963C11.2502 13.4213 10.8752 13.7963 10.4168 13.7963C9.9585 13.7963 9.5835 13.4213 9.5835 12.963V5.04631H8.3335V12.963C8.3335 14.113 9.26683 15.0463 10.4168 15.0463C11.5668 15.0463 12.5002 14.113 12.5002 12.963V4.21297C12.5002 2.37131 11.0085 0.879639 9.16683 0.879639C7.32516 0.879639 5.8335 2.37131 5.8335 4.21297V14.6296C5.8335 17.163 7.8835 19.213 10.4168 19.213C12.9502 19.213 15.0002 17.163 15.0002 14.6296V5.04631H13.7502Z",
        fill: variables["dark-600"],
        width: 20,
        height: 20
    }
];

const subMenuTogglerStyle = {
    position: "absolute",
    right: 0
};

export function SideBar() {
    const user = useSelector((state) => state?.user);
    const { openTabs = [] } = useSelector(state => state?.sidebar);

    const email = user?.email;
    const dispatch = useDispatch();

    const [isActive, setActive] = useState(false); // Sidebar Toggle
    const toggleClass = () => {
        setActive(!isActive);
    };
    const { pathname } = useLocation();

    const [hoverClass, setHoverClass]= useState(""); // Sidebar Toggle

    const handleDropdownClick = (tabName = "", lastPathName) => {
        if(!tabName) return;

        if(lastPathName === window.location.pathname && openTabs.includes(tabName)) {
            dispatch(setOpenTabs(openTabs.filter(item => item !== tabName)));
            return;
        }
        dispatch(setOpenTabs([...openTabs, tabName]));
    };

    // Sidebar Toggle
    const mouseEntryHandler = () => {
        if(isActive) {
            setHoverClass("hover-state");
        }
    };

    // Sidebar Toggle
    const mouseLeaveHandler = () => {
        if(isActive) {
            setHoverClass("");
        }
    };

    useEffect(() => {
        const activeSubTab = SIDEBAR_ITEMS.find(menuItem =>
            menuItem.sublinks?.includes(pathname)
        );
        if(activeSubTab?.name) {
            dispatch(setOpenTabs([...openTabs, activeSubTab.name]));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        dispatch(toggleSidebar(!isActive));
    }, [isActive, dispatch]);

    return (
        <div
            className ={cx(
                "navbar navbar-light navbar-vertical navbar-expand-xl sidebar-wrapper",
                hoverClass,
                isActive && "navbar-vertical--min"
            )}
            onMouseEnter={mouseEntryHandler}
            onMouseLeave={mouseLeaveHandler}
        >
            <div className="d-flex align-items-center py-3 navbar-brand">
                <Logo/>
            </div>
            <div className="navigation-toggler">
                <a className="menu-toggle btn btn-link d-flex align-items-center justify-content-center" onClick={toggleClass} >
                    {hoverClass ? <FaAngleRight /> : <FaAngleLeft />}
                </a>
            </div>
            <div className="sidebar">
                <div className="navigation">
                    <ul className="nav flex-column " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {
                            SIDEBAR_ITEMS.filter((item) => {
                                // filter based on whitelisted emails
                                if(item.name === OUTSOURCING && !OUTSOURCING_EMAILS.includes(email)) return false;
                                if(item.name === UPLOAD_FILE && !FILE_UPLOAD_EMAILS.includes(email)) return false;
                                return true;
                            }).map((item, index) => (
                                <Fragment key={item.id}>
                                    <li key={item.id} className="nav-item">
                                        {item.title && (!isActive || hoverClass === "hover-state") && <div className="nav-title">{item.title}</div>}
                                        <div
                                            className = {cx(
                                                SUBMENU_CONTAINERS.includes(item.name) && "nav-link-wrapper has-tooltip",
                                                (pathname === item.link || item?.sublinks?.includes(pathname)) && "active"
                                            )}
                                            onClick={() => {
                                                if(SUBMENU_CONTAINERS.includes(item.name))
                                                    handleDropdownClick(item.name, pathname);
                                            }}
                                        >
                                            <Link
                                                key={item.id}
                                                className={cx(
                                                    "nav-link has-tooltip w-100",
                                                    pathname === item.link && "active"
                                                )}
                                                data-tippy-placement="right"
                                                title={item.name} id={item.id}
                                                data-toggle="tab" href={item.link}
                                                role="tab"
                                                aria-controls="v-pills-home"
                                                aria-selected="true"
                                                to={item.link}
                                            >
                                                {item.svgPath && (
                                                    <svg width={item.width} height={item.height} viewBox={`0 0 ${item.width} ${item.height}`} fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
                                                        <path d={item.svgPath} fill={item.fill}/>
                                                    </svg>
                                                )}
                                                {item.icon && <div className="mr-2">{item.icon}</div>}
                                                <span>{item.name}</span>
                                                <div className="tooltip">{item.name}</div>
                                            </Link>

                                            {SUBMENU_CONTAINERS.includes(item.name) && (
                                                <a
                                                    className={cx("submenu-toggler", openTabs.includes(item.name) && "collapsed")}
                                                    data-toggle="collapse" aria-expanded={openTabs.includes(item.name) ? true : false}
                                                    aria-controls="dropdownMortage"
                                                    style={subMenuTogglerStyle}
                                                >
                                                    <ArrowForward style={{ height: 16 }} className="icon"/>
                                                </a>
                                            )}
                                        </div>
                                        {item?.subtab && (
                                            <ul className ={cx("submenu collapse", openTabs.includes(item.name) && "show")}>
                                                {item.subtab.filter(tab => {
                                                    if(tab.name === DATA_VERIFICATION && !FILE_UPLOAD_EMAILS.includes(email)) return false;
                                                    if(tab.name === LEARNING_HUB && !LEARNING_HUB_EMAILS.includes(email)) return false;
                                                    if(tab.name === PEOPLE_AND_CULTURE && !PEOPLE_AND_CULTURE_EMAILS.includes(email))
                                                        return false;
                                                    if(tab.name === MARKETING && !MARKETING_REPORTS_EMAILS.includes(email)) return false;
                                                    if(tab.name === TECHNOLOGY && !TECHNOLOGY_REPORTS_EMAILS.includes(email)) return false;
                                                    return true;
                                                }).map(tab => (
                                                    <li
                                                        key={tab.id}
                                                        className="p-relative"
                                                        onClick={() => handleDropdownClick(tab.name, pathname)}
                                                    >
                                                        <Link
                                                            key={tab.id}
                                                            className={cx(
                                                                "dropdown-item submenu-link",
                                                                (pathname === tab.link || tab?.sublinks?.includes(pathname)) && "active"
                                                            )}
                                                            data-tippy-placement="right"
                                                            title={tab.name}
                                                            id={tab.id}
                                                            data-toggle="tab"
                                                            href={tab.link}
                                                            role="tab"
                                                            aria-controls="v-pills-home"
                                                            aria-selected="true"
                                                            to={tab.link}
                                                        >
                                                            <span>{tab.name}</span>
                                                            <div className="tooltip">{tab.name}</div>
                                                        </Link>

                                                        {tab.subtab && (
                                                            <a
                                                                className={cx("submenu-toggler p-absolute", openTabs.includes(tab.name) && "collapsed")}
                                                                data-toggle="collapse" aria-expanded={openTabs.includes(tab.name) ? true : false}
                                                                aria-controls="dropdownMortage"
                                                                style={{ right: 0, top: 8 }}
                                                            >
                                                                <ArrowForward style={{ height: 16 }} className="icon"/>
                                                            </a>
                                                        )}

                                                        {tab.subtab && openTabs.includes(tab.name) && (
                                                            <>
                                                                <div style={{ height: 4 }} />
                                                                <ul className ={cx("submenu submenu-level-2 collapse", openTabs.includes(item.name) && "show")}>
                                                                    {tab.subtab.filter(tab => {
                                                                        if(tab.name === LEARNING_AND_DEVELOPMENT &&
                                                                            !LEARNING_AND_DEVELOPMENT_EMAILS.includes(email))
                                                                            return false;
                                                                        if(tab.name === TURNOVER_TRENDS &&
                                                                            !TURNOVER_TREND_EMAILS.includes(email))
                                                                            return false;
                                                                        return true;
                                                                    }).map(tab => (
                                                                        <li key={tab.id}>
                                                                            <Link
                                                                                key={tab.id}
                                                                                className={cx("dropdown-item submenu-link", (pathname === tab.link || tab?.sublinks?.includes(pathname)) && "active" )}
                                                                                data-tippy-placement="right"
                                                                                title={tab.name}
                                                                                id={tab.id}
                                                                                data-toggle="tab"
                                                                                href={tab.link}
                                                                                role="tab"
                                                                                aria-controls="v-pills-home"
                                                                                aria-selected="true"
                                                                                to={tab.link}
                                                                            >
                                                                                <span>{tab.name}</span>
                                                                                <div className="tooltip">{tab.name}</div>
                                                                            </Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                                <div style={{ height: 4 }} />
                                                            </>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                </Fragment>
                            ))
                        }
                    </ul>
                </div>

                {(!isActive || hoverClass === "hover-state") ? (
                    <div className="nav-btn-container">
                        {BUTTON_ITEMS.map(item => (
                            <Link to={item.link} key={item.id} className="btn btn-primary d-flex align-items-center justify-content-center w-100 nav-btn">
                                <svg width={item.width} height={item.height} viewBox={`0 0 ${item.width} ${item.height}`} fill="none" xmlns="http://www.w3.org/2000/svg" className="icon mr-1">
                                    <path d={item.svgPath} fill="white" />
                                </svg>
                                <span>{item.name}</span>
                            </Link>
                        ))}
                    </div>
                ) : (
                    <div className="nav-btn-container">
                        {BUTTON_ITEMS.map(item => (
                            <svg
                                key={item.id}
                                width={item.width}
                                height={item.height}
                                viewBox={`0 0 ${item.width} ${item.height}`}
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={cx("icon", pathname === item.link && "icon-active")}
                            >
                                <path
                                    d={item.svgPath}
                                    fill={pathname === item.link ? variables["primary-700"] : item.fill}
                                />
                            </svg>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
