import produce from "immer";

export const initialState = {
    sankeyData: {},
    sankeyStats: {},
    safSentDropoffStats: {}
};

export const SANKEY_ACTION_TYPES = Object.freeze({
    FETCHING_SANKEY_DATA: "FETCHING_SANKEY_DATA",
    FETCHING_SANKEY_STATS: "FETCHING_SANKEY_STATS",
    FETCHING_SAF_SENT_DROPOFF_STATS: "FETCHING_SAF_SENT_DROPOFF_STATS",

    CLEAR_SANKEY_DATA: "CLEAR_SANKEY_DATA",
    CLEAR_SANKEY_STATS: "CLEAR_SANKEY_STATS",
    CLEAR_SAF_SENT_DROPOFF_STATS: "CLEAR_SAF_SENT_DROPOFF_STATS"
});

export const sankeyReducer = produce((draft, action) => {
    switch(action.type) {
        // Sankey Data
        case SANKEY_ACTION_TYPES.FETCHING_SANKEY_DATA:
            draft.sankeyData = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case SANKEY_ACTION_TYPES.CLEAR_SANKEY_DATA:
            draft.sankeyData = null;
            return draft;

        // Sankey Stats
        case SANKEY_ACTION_TYPES.FETCHING_SANKEY_STATS:
            draft.sankeyStats = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case SANKEY_ACTION_TYPES.CLEAR_SANKEY_STATS:
            draft.sankeyStats = null;
            return draft;

        // Sankey Dropoff Stats
        case SANKEY_ACTION_TYPES.FETCHING_SAF_SENT_DROPOFF_STATS:
            draft.safSentDropoffStats = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case SANKEY_ACTION_TYPES.CLEAR_SAF_SENT_DROPOFF_STATS:
            draft.safSentDropoffStats = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
