/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from "classnames";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleSignOut } from "../../../actions";
import { useClickOutside } from "../../../hooks/useClickOutside";
import variables from "../../../scss/abstracts/_exports.scss";

export function Header() {
    const dropdownRef = useRef();
    const user = useSelector((state) => state.user);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dispatch = useDispatch();

    const toggleLogout = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    useClickOutside(dropdownRef, () => setIsDropdownOpen(false));

    return (
        <div className="page-header">
            <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand">
                <h4 className="title mb-0">Dashboard</h4>
                <ul className="flex-grow-1 navbar-nav navbar-nav-icons ml-auto flex-row align-items-center justify-content-end">
                    <li className={cx("nav-item dropdown", isDropdownOpen && "show")}>
                        <a className="nav-link d-flex pe-0 ps-2 align-items-center" id="navbarDropdownUser" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={toggleLogout}>
                            <div className="user-info text-right mr-3 pl-2">
                                <strong className="user-username ">{user ? user.name : ""}</strong>
                                <span className=" d-block user-email">{user ? user.email : ""}</span>
                            </div>
                            <div className="avatar avatar-xl border  rounded-circle" >
                                <img className="rounded-circle" src={user ? user.picture: ""} alt="user" height="36"/>
                            </div>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.41 8.58997L12 13.17L16.59 8.58997L18 9.99997L12 16L6 9.99997L7.41 8.58997Z" fill={variables["dark-600"]} /></svg>
                            </span>
                        </a>
                        <div ref={dropdownRef} className={cx("dropdown-menu dropdown-menu-end py-0 dropdown-menu-right", isDropdownOpen && "show")} aria-labelledby="navbarDropdownUser">
                            <div className="px-2 pt-2 pb-1">
                                <a
                                    onClick={() => dispatch(handleSignOut)}
                                    className="dropdown-item form-label form-label-sm btn btn-link"
                                >
                                Logout
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
