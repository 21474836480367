
import produce from "immer";

import { componentName } from "../../constants";
import { mapBrokerIsTeamTableData } from "../../mappers";

export const initialState = {
    pipelineReport: {},
    notContactData: {},
    insideSalesWorkload: {},
    leadOpportunityReport: {},
    brokerData: {},
    insideSalesData: {},
    [componentName.webLeads]: {}
};

export const OVERVIEW_ACTION_TYPES = Object.freeze({
    FETCH_PIPELINE_REPORT_DATA: "FETCH_PIPELINE_REPORT_DATA",
    FETCH_BROKER_IS_DATA: "FETCH_BROKER_IS_DATA",
    FETCH_NOT_CONTACT_DATA: "FETCH_NOT_CONTACT_DATA",
    FETCH_IS_WORKLOAD_DATA: "FETCH_IS_WORKLOAD_DATA",
    FETCH_LEAD_OPPORTUNITY_REPORT_DATA: "FETCH_LEAD_OPPORTUNITY_REPORT_DATA",
    FETCH_WEB_LEADS_REPORT: "FETCH_WEB_LEADS_REPORT",

    CLEAR_PIPELINE_REPORT_DATA: "CLEAR_PIPELINE_REPORT_DATA",
    CLEAR_BROKER_IS_DATA: "CLEAR_BROKER_IS_DATA",
    CLEAR_NOT_CONTACT_DATA: "CLEAR_NOT_CONTACT_DATA",
    CLEAR_IS_WORKLOAD_DATA: "CLEAR_IS_WORKLOAD_DATA",
    CLEAR_LEAD_OPPORTUNITY_REPORT_DATA: "CLEAR_LEAD_OPPORTUNITY_REPORT_DATA",
    CLEAR_WEB_LEADS_REPORT: "CLEAR_WEB_LEADS_REPORT"
});

export const overviewReducer = produce((draft, action) => {
    switch(action.type) {
        // Broker IS Data
        case OVERVIEW_ACTION_TYPES.FETCH_BROKER_IS_DATA:
            const brokerData = mapBrokerIsTeamTableData(action.payload.data, "broker");
            const insideSalesData = mapBrokerIsTeamTableData(action.payload.data, "insideSales");

            draft.brokerData = {
                data: brokerData,
                date: action.payload?.data?.date,
                stateCreatedAt: new Date()
            };
            draft.insideSalesData = {
                data: insideSalesData,
                date: action.payload?.data?.date,
                stateCreatedAt: new Date()
            };
            return draft;

        case OVERVIEW_ACTION_TYPES.CLEAR_BROKER_IS_DATA:
            draft.brokerData = null;
            draft.insideSalesData = null;
            return draft;

        // Not Contact Data
        case OVERVIEW_ACTION_TYPES.FETCH_NOT_CONTACT_DATA:
            draft.notContactData = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case OVERVIEW_ACTION_TYPES.CLEAR_NOT_CONTACT_DATA:
            draft.notContactData = null;
            return draft;

        // IS Workload Data
        case OVERVIEW_ACTION_TYPES.FETCH_IS_WORKLOAD_DATA:
            draft.insideSalesWorkload = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case OVERVIEW_ACTION_TYPES.CLEAR_IS_WORKLOAD_DATA:
            draft.insideSalesWorkload = null;
            return draft;

        // Lead Opportunity Data
        case OVERVIEW_ACTION_TYPES.FETCH_LEAD_OPPORTUNITY_REPORT_DATA:
            draft.leadOpportunityReport = {
                ...action.payload.ldas,
                stateCreatedAt: new Date()
            };
            return draft;

        case OVERVIEW_ACTION_TYPES.CLEAR_LEAD_OPPORTUNITY_REPORT_DATA:
            draft.leadOpportunityReport = null;
            return draft;

        // Pipeline Report Data
        case OVERVIEW_ACTION_TYPES.FETCH_PIPELINE_REPORT_DATA:
            draft[componentName.pipelineReport] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case OVERVIEW_ACTION_TYPES.CLEAR_PIPELINE_REPORT_DATA:
            draft[componentName.pipelineReport] = null;
            return draft;

        // Web Leads Report Data
        case OVERVIEW_ACTION_TYPES.FETCH_WEB_LEADS_REPORT:
            draft[componentName.webLeads] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case OVERVIEW_ACTION_TYPES.CLEAR_WEB_LEADS_REPORT:
            draft[componentName.webLeads] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
