const checkIfPageReloaded = () => {
    const pageAccessedByReload = window.performance
        .getEntriesByType("navigation")
        .map((nav) => nav.type)
        .includes("reload");

    return pageAccessedByReload;
};

export default checkIfPageReloaded;
