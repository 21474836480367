import produce from "immer";

import { componentName } from "../../constants";

export const initialState = {
    [componentName.monthlySales]: {}
};

export const SALES_REPORTS_ACTION_TYPES = Object.freeze({
    FETCH_MONTHLY_SALES_DATA: "FETCH_MONTHLY_SALES_DATA",

    CLEAR_MONTHLY_SALES_DATA: "CLEAR_MONTHLY_SALES_DATA"
});

export const salesReportsReducer = produce((draft, action) => {
    switch(action.type) {
        // Weekly Broker Score
        case SALES_REPORTS_ACTION_TYPES.FETCH_MONTHLY_SALES_DATA:
            draft[componentName.monthlySales] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case SALES_REPORTS_ACTION_TYPES.CLEAR_MONTHLY_SALES_DATA:
            draft[componentName.monthlySales] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
