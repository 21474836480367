export const Broker = [
    "Ajar Rajbhandari",
    "Amine Berrada",
    "Anil Joshi",
    "Aseem Sangachhe",
    "Gunjan Shree Thapa",
    "Jenish Manandhar",
    "Jonathan Preston",
    "Kenneth Wai Loon Cheah",
    "Mandira Shrestha",
    "Manish Rana Magar",
    "Mohit Lal Pradhan",
    "Nirayu Shakya",
    "Prakash Rai",
    "Pramesh Vaidya",
    "Preeti Kowshik",
    "Robert Mo",
    "Rojan Paudel",
    "Rojina Shivabhakti",
    "Romy Dhungana",
    "Sheng Ye",
    "Siddhartha Dhar Bajracharya",
    "Steven Chan",
    "Sunish Vaidya",
    "Trong (Vivienne) Than",
    "Ujjwal Maharjan"
];

export const InsideSales = [
    "Akshay Basnet",
    "Arian Badgami",
    "Bibek Jaiswal",
    "Deepika Shakya",
    "Mohd Kamran Siddiqui",
    "Nipsha Dhungana",
    "Noyola (Shreya) Chhetri",
    "Pranita Kc",
    "Pratima (Peace)  Bhandari",
    "Rasmita Ghimire",
    "Ronit Shrestha",
    "Sadina Magar",
    "Sagar (Sherlock) Gyawali",
    "Sama Shakya",
    "Sandesh Shrestha",
    "Shashwat Regmi",
    "Suprabha Sharma"
];
