import { combineReducers } from "redux";

import { formsReducer } from "./forms";
import { loaderReducer } from "./loaderReducer";
import { modeReducer } from "./modeReducer";
import { mortgageReducer } from "./mortgageBroking";
import { operationReducer } from "./operations";
import { outsourcingReducer } from "./outsourcing";
import { overviewReducer } from "./overview";
import { sankeyReducer } from "./sankey";
import { sidebarReducer } from "./sidebarReducer";
import { userReducer } from "./userReducer";

export default combineReducers({
    loader: loaderReducer,
    mortgage: mortgageReducer,
    operations: operationReducer,
    outSourcing: outsourcingReducer,
    overview: overviewReducer,
    forms: formsReducer,
    sankey: sankeyReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    mode: modeReducer
});
