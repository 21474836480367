import { ACTIONS_TYPES } from "../reducers/sidebarReducer";

export const toggleSidebar = (value) => {
    if(value === true)
        return {
            type: ACTIONS_TYPES.OPEN_SIDEBAR,
            payload: value
        };

    return {
        type: ACTIONS_TYPES.CLOSE_SIDEBAR,
        payload: value
    };
};

export const setOpenTabs = (value = []) => {
    return {
        type: ACTIONS_TYPES.SET_OPEN_TABS,
        payload: [...new Set(value)]
    };
};

export const setActiveMenuItem = (id) => {
    return {
        type: ACTIONS_TYPES.SET_ACTIVE_MENU_ITEM,
        payload: id
    };
};
