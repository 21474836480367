import produce from "immer";

export const initialState = {
    recruiterNps: {},
    staffAllocation: {}
};

export const OPERATION_OVERVIEW_ACTION_TYPES = Object.freeze({
    FETCHING_STAFF_ALLOCATION: "FETCHING_STAFF_ALLOCATION",
    FETCHING_RECRUITER_NPS: "FETCHING_RECRUITER_NPS",

    CLEAR_STAFF_ALLOCATION: "CLEAR_STAFF_ALLOCATION",
    CLEAR_RECRUITER_NPS: "CLEAR_RECRUITER_NPS"
});

export const operationOverviewReducer = produce((draft, action) => {
    switch(action.type) {
        // Recruiter NPS
        case OPERATION_OVERVIEW_ACTION_TYPES.FETCHING_RECRUITER_NPS:
            draft.recruiterNps = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case OPERATION_OVERVIEW_ACTION_TYPES.CLEAR_RECRUITER_NPS:
            draft.recruiterNps = null;
            return draft;

        // Staff Allocation
        case OPERATION_OVERVIEW_ACTION_TYPES.FETCHING_STAFF_ALLOCATION:
            draft.staffAllocation = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case OPERATION_OVERVIEW_ACTION_TYPES.CLEAR_STAFF_ALLOCATION:
            draft.staffAllocation = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
