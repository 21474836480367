import { tab } from "../../../constants";

export function PageBody({ children, pathname }) {
    return (
        <div className="page-body">
            <div className="container-fluid">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="companyoverviewtab" role="tabpanel" aria-labelledby="home-tab">
                        <div className="content-header d-flex align-items-center justify-content-between">
                            <h6>{tab[pathname]||""}</h6>
                        </div>
                        <div className="content-container mt-4">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
