import produce from "immer";

import { componentName } from "../../constants";

export const initialState = {
    [componentName.lpsWorkloads]: {},
    [componentName.caWorkloads]: {},
    [componentName.crWorkloads]: {},
    [componentName.dataProcessingWorkloads]: {},
    [componentName.outsourcingWorkloads]: {}
};

export const WORKLOAD_REPORTS_ACTION_TYPES = Object.freeze({
    FETCH_LPS_WORKLOADS_DATA: "FETCH_LPS_WORKLOADS_DATA",
    FETCH_CA_WORKLOADS_DATA: "FETCH_CA_WORKLOADS_DATA",
    FETCH_CR_WORKLOADS_DATA: "FETCH_CR_WORKLOADS_DATA",
    FETCH_DATA_PROCESSING_WORKLOADS_DATA: "FETCH_DATA_PROCESSING_WORKLOADS_DATA",
    FETCH_OUTSOURCING_WORKLOADS_DATA: "FETCH_OUTSOURCING_WORKLOADS_DATA",

    CLEAR_LPS_WORKLOADS_DATA: "CLEAR_LPS_WORKLOADS_DATA",
    CLEAR_CA_WORKLOADS_DATA: "CLEAR_CA_WORKLOADS_DATA",
    CLEAR_CR_WORKLOADS_DATA: "CLEAR_CR_WORKLOADS_DATA",
    CLEAR_DATA_PROCESSING_WORKLOADS_DATA: "CLEAR_DATA_PROCESSING_WORKLOADS_DATA",
    CLEAR_OUTSOURCING_WORKLOADS_DATA: "CLEAR_OUTSOURCING_WORKLOADS_DATA"
});

export const workloadReportsReducer = produce((draft, action) => {

    switch(action.type) {
        // LPS & Learning Hub Workloads
        case WORKLOAD_REPORTS_ACTION_TYPES.FETCH_LPS_WORKLOADS_DATA:
            draft[componentName.lpsWorkloads] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case WORKLOAD_REPORTS_ACTION_TYPES.CLEAR_LPS_WORKLOADS_DATA:
            draft[componentName.lpsWorkloads] = null;
            return draft;

        // CA Workloads
        case WORKLOAD_REPORTS_ACTION_TYPES.FETCH_CA_WORKLOADS_DATA:
            draft[componentName.caWorkloads] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case WORKLOAD_REPORTS_ACTION_TYPES.CLEAR_CA_WORKLOADS_DATA:
            draft[componentName.caWorkloads] = null;
            return draft;

        // CR Workloads
        case WORKLOAD_REPORTS_ACTION_TYPES.FETCH_CR_WORKLOADS_DATA:
            draft[componentName.crWorkloads] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case WORKLOAD_REPORTS_ACTION_TYPES.CLEAR_CR_WORKLOADS_DATA:
            draft[componentName.crWorkloads] = null;
            return draft;

        // Data Processing Workloads
        case WORKLOAD_REPORTS_ACTION_TYPES.FETCH_DATA_PROCESSING_WORKLOADS_DATA:
            draft[componentName.dataProcessingWorkloads] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case WORKLOAD_REPORTS_ACTION_TYPES.CLEAR_DATA_PROCESSING_WORKLOADS_DATA:
            draft[componentName.dataProcessingWorkloads] = null;
            return draft;

        // Outsourcing Workloads
        case WORKLOAD_REPORTS_ACTION_TYPES.FETCH_OUTSOURCING_WORKLOADS_DATA:
            draft[componentName.outsourcingWorkloads] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case WORKLOAD_REPORTS_ACTION_TYPES.CLEAR_OUTSOURCING_WORKLOADS_DATA:
            draft[componentName.outsourcingWorkloads] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
