import produce from "immer";

export const initialState = {
    peakHourCall: {},
    brokerAvailability: {},
    agentCallInfo: {}
};

export const CALL_ANALYSIS_ACTION_TYPES = Object.freeze({
    FETCHING_PEAK_HOUR_CALL_DATA: "FETCHING_PEAK_HOUR_CALL_DATA",
    FETCHING_BROKER_AVAILABILITY_DATA: "FETCHING_BROKER_AVAILABILITY_DATA",
    FETCHING_AGENT_CALL_DATA: "FETCHING_AGENT_CALL_DATA",

    CLEAR_PEAK_HOUR_CALL_DATA: "CLEAR_PEAK_HOUR_CALL_DATA",
    CLEAR_BROKER_AVAILABILITY_DATA: "CLEAR_BROKER_AVAILABILITY_DATA",
    CLEAR_AGENT_CALL_DATA: "CLEAR_AGENT_CALL_DATA"
});

export const callAnalysisReducer = produce((draft, action) => {
    switch(action.type) {
        // Peak Hour Call Data
        case CALL_ANALYSIS_ACTION_TYPES.FETCHING_PEAK_HOUR_CALL_DATA:
            draft.peakHourCall = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case CALL_ANALYSIS_ACTION_TYPES.CLEAR_PEAK_HOUR_CALL_DATA:
            draft.peakHourCall = null;
            return draft;

        // Agent Availability Data
        case CALL_ANALYSIS_ACTION_TYPES.FETCHING_BROKER_AVAILABILITY_DATA:
            draft.brokerAvailability = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case CALL_ANALYSIS_ACTION_TYPES.CLEAR_BROKER_AVAILABILITY_DATA:
            draft.brokerAvailability = null;
            return draft;

        // Agent Call Summary
        case CALL_ANALYSIS_ACTION_TYPES.FETCHING_AGENT_CALL_DATA:
            draft.agentCallInfo = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case CALL_ANALYSIS_ACTION_TYPES.CLEAR_AGENT_CALL_DATA:
            draft.agentCallInfo = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
