import produce from "immer";

export const initialState = {
    learningHub: {}
};

export const LEARNING_HUB_ACTION_TYPES = Object.freeze({
    FETCH_LEARNING_HUB_DATA: "FETCH_LEARNING_HUB_DATA",

    CLEAR_LEARNING_HUB_DATA: "CLEAR_LEARNING_HUB_DATA"
});

export const learningHubReducer = produce((draft, action) => {
    switch(action.type) {
        // Learning Hub
        case LEARNING_HUB_ACTION_TYPES.FETCH_LEARNING_HUB_DATA:
            draft.learningHub = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case LEARNING_HUB_ACTION_TYPES.CLEAR_LEARNING_HUB_DATA:
            draft.learningHub = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
