export const COMPANY_OVERVIEW = "Company Overview";
export const MORTGAGE_BROKING = "Mortgage Broking";
export const OUTSOURCING = "Outsourcing";
export const OPERATIONS = "Operations";
export const SANKEY_DIAGRAM = "Sankey Diagram";
export const UPLOAD_FILE = "Upload File";
export const FORMS = "Forms";

// MORTGAGE_BROKING Sub Menus
export const DISCHARGE = "Discharge";
export const MARKETING = "Marketing";
export const EMAIL_MARKETING = "Email Marketing";
export const REVIEW_AND_RATING = "Review and Rating";
export const LDAS_REPORTS = "LDAS Reports";
export const ACTIVITY_REPORTS = "Activity Reports";
export const SALES_REPORTS = "Sales Reports";
export const WORKLOAD_REPORTS = "Workload Reports";
export const BROKER_REPORTS = "Broker Reports";
export const LQS_REPORTS = "Lead Rating Report";

// OPERATIONS Sub Menus
export const LEARNING_HUB = "Learning Hub";
export const PEOPLE_AND_CULTURE = "People And Culture";
export const CALL_ANALYSIS = "Call Analysis";
export const CHAT_REPORT = "Chat Analysis";
export const TECHNOLOGY = "Technology";

// OPERATIONS -> PEOPLE_AND_CULTURE Sub Menus
export const LEARNING_AND_DEVELOPMENT = "L & D";
export const TURNOVER_TRENDS = "Turnover Trends";
export const ENGAGEMENT_REPORTS = "Engagement Reports";

// FORMS Sub Menus
export const DISCHARGE_FORMS = "Discharge";
export const DATA_VERIFICATION = "Data Verification";

export const DATA_TEAM_EMAILS = [
    "admin@homeloanexperts.com.au",
    "becky@homeloanexperts.com.au",
    "kritish.b@homeloanexperts.com.au",
    "namrata.giri@homeloanexperts.com.au",
    "bikash.s@homeloanexperts.com.au",
    "madhav.g@homeloanexperts.com.au",
    "pratik.t@homeloanexperts.com.au",
    "rupa.c@homeloanexperts.com.au"
];

export const OUTSOURCING_EMAILS = [
    "alan.hemmings@homeloanexperts.com.au",
    "bhisan@homeloanexperts.com.au",
    "sharon@homeloanexperts.com.au",
    "brooke@homeloanexperts.com.au",
    "amrit@homeloanexperts.com.au",
    "pratik@homeloanexperts.com.au",
    "deepin.b@homeloanexperts.com.au",
    "mary@homeloanexperts.com.au",
    "dilasha.t@homeloanexperts.com.au",
    "dikxyta.p@homeloanexperts.com.au",
    "krismita.shrestha@homeloanexperts.com.au",
    "rahul@homeloanexperts.com.au",
    "binay@homeloanexperts.com.au",
    "saramsh@homeloanexperts.com.au",
    "sam@homeloanexperts.com.au",
    ...DATA_TEAM_EMAILS
];

export const FILE_UPLOAD_EMAILS = [
    ...DATA_TEAM_EMAILS
];

export const LEARNING_HUB_EMAILS = [
    ...OUTSOURCING_EMAILS,
    "summer@homeloanexperts.com.au",
    "ashma.m@homeloanexperts.com.au",
    "pooja.a@homeloanexperts.com.au",
    "sushandeep.g@homeloanexperts.com.au",
    "aasish.wagle@homeloanexperts.com.au",
    "sadikshya.shrestha@homeloanexperts.com.au",
    "akshay.jain@homeloanexperts.com.au",
    "merusha.a@homeloanexperts.com.au",
    "sonisha.maharjan@homeloanexperts.com.au",
    "shweta.hada@homeloanexperts.com.au",
    "upama.p@homeloanexperts.com.au",
    "aayushi.k@homeloanexperts.com.au",
    "sam@homeloanexperts.com.au",
    "saramsh@homeloanexperts.com.au",
    "rahul@homeloanexperts.com.au",
    "dilasha.t@homeloanexperts.com.au",
    "dikxyta.p@homeloanexperts.com.au",
    "krismita.shrestha@homeloanexperts.com.au",
    "susmita@homeloanexperts.com.au",
    "manisha.pokhrel@homeloanexperts.com.au"
];

export const PEOPLE_AND_CULTURE_EMAILS = [
    ...DATA_TEAM_EMAILS,
    "alan.hemmings@homeloanexperts.com.au",
    "amrit@homeloanexperts.com.au",
    "bhisan@homeloanexperts.com.au",
    "corepeopleandculture@homeloanexperts.com.au",
    "kritika.u@homeloanexperts.com.au",
    "kritika.d@homeloanexperts.com.au",
    "oscar@homeloanexperts.com.au",
    "otto@homeloanexperts.com.au",
    "reshma.dangol@homeloanexperts.com.au",
    "roy@homeloanexperts.com.au",
    "sharon@homeloanexperts.com.au",
    "sam@homeloanexperts.com.au",
    "saramsh@homeloanexperts.com.au",
    "shristy.m@homeloanexperts.com.au",
    "sachyam.gyawali@homeloanexperts.com.au",
    "sneha@homelaonexperts.com.au",
    "sneha.pradhan@homeloanexperts.com.au",
    "sejal.m@homeloanexperts.com.au",
    "hr.nepal@homelaonexperts.com.au"
];

export const LEARNING_AND_DEVELOPMENT_EMAILS = PEOPLE_AND_CULTURE_EMAILS;

export const ENGAGEMENT_EMAILS = PEOPLE_AND_CULTURE_EMAILS;

export const TURNOVER_TREND_EMAILS = PEOPLE_AND_CULTURE_EMAILS;

export const PRODUCT_TEAM = [
    "pratik@homeloanexperts.com.au",
    "adishree.basnet@homeloanexperts.com.au",
    "shambaran.k@homeloanexperts.com.au",
    "praveen.s@homeloanexperts.com.au",
    "sameer.bagale@homeloanexperts.com.au"
];

export const DEV_TEAM = [
    "pratik.t@homeloanexperts.com.au",
    "madhav.g@homeloanexperts.com.au",
    "bikash.s@homeloanexperts.com.au",
    "anshu.s@homeloanexperts.com.au",
    "smarika.t@homeloanexperts.com.au",
    "salina.m@homeloanexperts.com.au",
    "shraya.joshi@homeloanexperts.com.au"
];

export const DEVOPS_TEAM = [
    "pawan.c@homeloanexperts.com.au",
    "manish.p@homeloanexperts.com.au"
];

export const MARKETING_TEAM = [
    "andy@homeloanexperts.com.au",
    "niyukta@homeloanexperts.com.au",
    "roshan.k@homeloanexperts.com.au",
    "anamol.shrestha@homeloanexperts.com.au",
    "basu.kapali@homeloanexperts.com.au"
];

export const MARKETING_REPORTS_EMAILS = [
    "alan.hemmings@homeloanexperts.com.au",
    "otto@homeloanexperts.com.au",
    "bhisan@homeloanexperts.com.au",
    ...MARKETING_TEAM,
    ...PRODUCT_TEAM,
    ...DATA_TEAM_EMAILS
];

export const TECHNOLOGY_REPORTS_EMAILS = [
    "alan.hemmings@homeloanexperts.com.au",
    "otto@homeloanexperts.com.au",
    "bhisan@homeloanexperts.com.au",
    "deepin.b@homeloanexperts.com.au",
    "mary@homeloanexperts.com.au",
    ...MARKETING_TEAM,
    ...PRODUCT_TEAM,
    ...DEV_TEAM,
    ...DEVOPS_TEAM,
    ...DATA_TEAM_EMAILS
];
