import { Auth } from "aws-amplify";

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const PENDO_API_KEY = process.env.REACT_APP_PENDO_API_KEY;

const config = {
    prod: {
        oauth: {
            domain: "id.pulse.homeloanexperts.com.au",
            scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
            redirectSignIn: "https://pulse.homeloanexperts.com.au",
            redirectSignOut: "https://pulse.homeloanexperts.com.au",
            responseType: "code",
            options: {
                AdvancedSecurityDataCollectionFlag: true
            }
        },
        amplify: {
            Auth: getCognitoSettings(),
            API: {
                endpoints: [
                    {
                        name: "hlePulseApi",
                        region: "ap-southeast-2",
                        endpoint: "https://api.pulse.homeloanexperts.com.au",
                        custom_header: async() => {
                            if(await Auth.currentUserInfo())
                                return getCustomHeader();
                        }
                    },
                    {
                        name: "leadEventsApi",
                        region: "ap-southeast-2",
                        endpoint: "https://leadevents.homeloanexperts.com.au/api",
                        custom_header: async() => {
                            if(await Auth.currentUserInfo())
                                return getCustomHeader();
                        }
                    },
                    {
                        name: "lqsApi",
                        region: "ap-southeast-2",
                        endpoint: "https://lqs-api.homeloanexperts.com.au",
                        custom_header: async() => {
                            if(await Auth.currentUserInfo())
                                return getCustomHeader();
                        }
                    }
                ]
            },
            Storage: {
                AWSS3: {
                    bucket: "hle-dataprod-ap-southeast-2-data-lake-upload-bucket",
                    region: "ap-southeast-2"
                }
            }
        },
        sentry: {
            dsn: SENTRY_DSN
        },
        hotjarScript: "",
        pendoScript: `
            (function() {
                (function(p, e, n, d, o) {var v, w, x, y, z;o=p[d]=p[d]||{};o._q=o._q||[];
                    v=["initialize", "identify", "updateOptions", "pageLoad", "track"];for(w=0, x=v.length;w<x;++w)(function(m) {
                        o[m]=o[m]||function() {o._q[m===v[0]?"unshift":"push"]([m].concat([].slice.call(arguments, 0)));};})(v[w]);
                    y=e.createElement(n);y.async=!0;y.src="https://cdn.pendo.io/agent/static/${PENDO_API_KEY}/pendo.js";
                    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y, z);})(window, document, "script", "pendo");
            })();
        `
    },
    data: {
        oauth: {
            domain: "id.data.homeloanexperts.net.au",
            scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
            redirectSignIn: "https://pulse.data.homeloanexperts.net.au",
            redirectSignOut: "https://pulse.data.homeloanexperts.net.au",
            responseType: "code",
            options: {
                AdvancedSecurityDataCollectionFlag: true
            }
        },
        amplify: {
            Auth: getCognitoSettings(),
            API: {
                endpoints: [
                    {
                        name: "hlePulseApi",
                        region: "ap-southeast-2",
                        endpoint: "https://pulse-api.data.homeloanexperts.net.au",
                        custom_header: async() => {
                            if(await Auth.currentUserInfo())
                                return getCustomHeader();
                        }
                    }
                ]
            },
            Storage: {
                AWSS3: {
                    bucket: "hle-data-ap-southeast-2-data-lake-upload-bucket",
                    region: "ap-southeast-2"
                }
            }
        },
        sentry: {
            dsn: SENTRY_DSN
        },
        hotjarScript: "",
        pendoScript: `
            (function() {
                (function(p, e, n, d, o) {var v, w, x, y, z;o=p[d]=p[d]||{};o._q=o._q||[];
                    v=["initialize", "identify", "updateOptions", "pageLoad", "track"];for(w=0, x=v.length;w<x;++w)(function(m) {
                        o[m]=o[m]||function() {o._q[m===v[0]?"unshift":"push"]([m].concat([].slice.call(arguments, 0)));};})(v[w]);
                    y=e.createElement(n);y.async=!0;y.src="https://cdn.pendo.io/agent/static/${PENDO_API_KEY}/pendo.js";
                    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y, z);})(window, document, "script", "pendo");
            })();
        `
    },
    default: {
        oauth: {
            domain: "id.data.homeloanexperts.net.au",
            scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
            redirectSignIn: "http://localhost:3000",
            redirectSignOut: "http://localhost:3000",
            responseType: "code",
            options: {
                AdvancedSecurityDataCollectionFlag: true
            }
        },
        amplify: {
            Auth: getCognitoSettings(),
            API: {
                endpoints: [
                    {
                        name: "hlePulseApi",
                        region: "ap-southeast-2",
                        endpoint: "https://hphm3y57l6.execute-api.ap-southeast-2.amazonaws.com/data",
                        custom_header: async() => {
                            if(await Auth.currentUserInfo())
                                return getCustomHeader();
                        }
                    }
                ]
            },
            Storage: {
                AWSS3: {
                    bucket: "hle-data-ap-southeast-2-data-lake-upload-bucket",
                    region: "ap-southeast-2"
                }
            }
        },
        hotjarScript: "",
        pendoScript: ""
    }
};

// eslint-disable-next-line no-unused-vars
async function getCustomHeader() {
    return {
        "Content-Type": "application/octet-stream",
        "AuthorisationToken": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    };
}

function getCognitoSettings() {
    return {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID
    };
}

const react_script_env = process.env.REACT_APP_ENV;

function getEnvironment() {
    var env;
    switch(react_script_env) {
        case "LOCAL":
            env = "default";
            break;
        case "DATA":
            env = "data";
            break;
        case "DATAPROD":
            env = "prod";
            break;
        default:
            env = "default";
    }
    return env;
}

export const env = getEnvironment();
export default config[env];
