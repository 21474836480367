// Checks if a React Element is in view

import { throttle } from "lodash";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { setActiveMenuItem } from "../actions/sidebarAction";
import { componentDetails } from "../constants";

export const useMenuHighlighter = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const components = useMemo(() => {
        return Object
            .values(componentDetails)
            .filter(item => item.page === location.pathname && !!item.id)
            .map(item => ({ name: item.name, id: item.id }));

    }, [location]);

    useEffect(() => {

        // is fired initially when location is changed
        const initialHighlighter = () => {
            if(components.length) dispatch(setActiveMenuItem(components[0].id));
        };

        initialHighlighter();

        // is fired when scroll event occurs
        const scrollHighlighter = () => {
            const reordered = components
                .map(item => {
                    const el = document.getElementById(item.id);
                    if(!el) return item;

                    const { top } = el.getBoundingClientRect();
                    return { ...item, top };
                })
                .sort((a, b) => a.top - b.top);

            const activeComponent = reordered
                .find(item => item.top >= 0 && item.top <= window.innerHeight) || reordered[0];

            dispatch(setActiveMenuItem(activeComponent?.id));
        };

        const scrollEvent = window.addEventListener(
            "scroll",
            throttle(() => scrollHighlighter(), 1000)
        );

        return () => {
            window.removeEventListener("scroll", scrollEvent);
        };

    }, [location, dispatch, components]);
};
