import produce from "immer";

import { componentName } from "../../constants";
import { getFirstDayOfCurrentMonth } from "../../utils/datepicker";

export const initialState = {
    [componentName.webLeads]: {},
    [componentName.leadConversion]: {
        data: {},
        filters: {
            from: getFirstDayOfCurrentMonth(),
            to: new Date().toISOString().split("T")[0],
            primaryDimension: "pluginName"
        }
    }
};

export const TECHNOLOGY_ACTION_TYPES = Object.freeze({
    FETCH_WEB_LEADS_REPORT: "FETCH_WEB_LEADS_REPORT",

    CLEAR_WEB_LEADS_REPORT: "CLEAR_WEB_LEADS_REPORT",

    FETCH_LEAD_CONVERSION_REPORT: "FETCH_LEAD_CONVERSION_REPORT",

    SET_LEAD_CONVERSION_FILTER: "SET_LEAD_CONVERSION_FILTER"
});

export const technologyReducer = produce((draft, action) => {

    switch(action.type) {
        // Web Leads Report Data
        case TECHNOLOGY_ACTION_TYPES.FETCH_WEB_LEADS_REPORT:
            draft[componentName.webLeads] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case TECHNOLOGY_ACTION_TYPES.CLEAR_WEB_LEADS_REPORT:
            draft[componentName.webLeads] = null;
            return draft;

        case TECHNOLOGY_ACTION_TYPES.FETCH_LEAD_CONVERSION_REPORT:
            draft[componentName.leadConversion] = {
                ...draft[componentName.leadConversion],
                data: action.payload
            };
            return draft;

        case TECHNOLOGY_ACTION_TYPES.SET_LEAD_CONVERSION_FILTER:
            draft[componentName.leadConversion] = {
                ...draft[componentName.leadConversion],
                filters: {
                    ...draft[componentName.leadConversion]?.filters,
                    ...action.payload
                }
            };
        default:
            return draft;
    }
}, initialState);
