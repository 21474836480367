import produce from "immer";

const initialState = {
    isOpen: false,
    openTabs: [],
    activeMenuItem: null // holds component id for <Menu />
};

export const ACTIONS_TYPES = Object.freeze({
    OPEN_SIDEBAR: "OPEN_SIDEBAR",
    CLOSE_SIDEBAR: "CLOSE_SIDEBAR",

    SET_OPEN_TABS: "SET_OPEN_TABS",

    SET_ACTIVE_MENU_ITEM: "SET_ACTIVE_MENU_ITEM"
});


export const sidebarReducer = produce((draft, action) => {
    const { type, payload } = action;

    switch(type) {
        case ACTIONS_TYPES.OPEN_SIDEBAR:
            draft.isOpen = true;
            return draft;

        case ACTIONS_TYPES.CLOSE_SIDEBAR:
            draft.isOpen = false;
            return draft;

        case ACTIONS_TYPES.SET_OPEN_TABS:
            draft.openTabs = payload;
            return draft;

        case ACTIONS_TYPES.SET_ACTIVE_MENU_ITEM:
            draft.activeMenuItem = payload;
            return draft;

        default:
            return draft;
    }
}, initialState);
