import produce from "immer";

export const initialState = {
    newsLetterRba: [],
    newsLetterEMag: [],
    newsLetterConnective: {}
};

export const EMAIL_MARKETING_ACTIONS_TYPES = Object.freeze({
    FETCHING_NEWS_LETTER_RBA_DATA: "FETCHING_NEWS_LETTER_RBA_DATA",
    FETCHING_NEWS_LETTER_E_MAG_DATA: "FETCHING_NEWS_LETTER_E_MAG_DATA",
    FETCHING_NEWS_LETTER_CONNECTIVE_DATA: "FETCHING_NEWS_LETTER_CONNECTIVE_DATA",

    CLEAR_NEWS_LETTER_RBA_DATA: "CLEAR_NEWS_LETTER_RBA_DATA",
    CLEAR_NEWS_LETTER_E_MAG_DATA: "CLEAR_NEWS_LETTER_E_MAG_DATA",
    CLEAR_NEWS_LETTER_CONNECTIVE_DATA: "CLEAR_NEWS_LETTER_CONNECTIVE_DATA"
});

export const emailMarketingReducer = produce((draft, action) => {
    switch(action.type) {
        // NewsLetter RBA
        case EMAIL_MARKETING_ACTIONS_TYPES.FETCHING_NEWS_LETTER_RBA_DATA:
            draft.newsLetterRba = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case EMAIL_MARKETING_ACTIONS_TYPES.CLEAR_NEWS_LETTER_RBA_DATA:
            draft.newsLetterRba = null;
            return draft;

        // NewsLetter Emag
        case EMAIL_MARKETING_ACTIONS_TYPES.FETCHING_NEWS_LETTER_E_MAG_DATA:
            draft.newsLetterEMag = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case EMAIL_MARKETING_ACTIONS_TYPES.CLEAR_NEWS_LETTER_E_MAG_DATA:
            draft.newsLetterEMag = null;
            return draft;

        // NewsLetter Connective
        case EMAIL_MARKETING_ACTIONS_TYPES.FETCHING_NEWS_LETTER_CONNECTIVE_DATA:
            draft.newsLetterConnective = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case EMAIL_MARKETING_ACTIONS_TYPES.CLEAR_NEWS_LETTER_CONNECTIVE_DATA:
            draft.newsLetterConnective = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
