import produce from "immer";

import { componentName } from "../../../constants";

export const initialState = {
    [componentName.turnoverRate]: {},
    [componentName.turnoverType]: {},
    [componentName.turnoverDuration]: {},
    [componentName.turnoverDepartment]: {},
    [componentName.turnoverPerformance]: {},
    [componentName.probation]: {}
};

export const TURNOVER_ACTION_TYPES = Object.freeze({
    FETCH_TURNOVER_RATE_DATA: "FETCH_TURNOVER_RATE_DATA",
    FETCH_TURNOVER_TYPE_DATA: "FETCH_TURNOVER_TYPE_DATA",
    FETCH_TURNOVER_DURATION_DATA: "FETCH_TURNOVER_DURATION_DATA",
    FETCH_TURNOVER_DEPARTMENT_DATA: "FETCH_TURNOVER_DEPARTMENT_DATA",
    FETCH_TURNOVER_PERFORMANCE_DATA: "FETCH_TURNOVER_PERFORMANCE_DATA",
    FETCH_PROBATION_DATA: "FETCH_PROBATION_DATA",

    CLEAR_TURNOVER_RATE_DATA: "CLEAR_TURNOVER_RATE_DATA",
    CLEAR_TURNOVER_TYPE_DATA: "CLEAR_TURNOVER_TYPE_DATA",
    CLEAR_TURNOVER_DURATION_DATA: "CLEAR_TURNOVER_DURATION_DATA",
    CLEAR_TURNOVER_DEPARTMENT_DATA: "CLEAR_TURNOVER_DEPARTMENT_DATA",
    CLEAR_TURNOVER_PERFORMANCE_DATA: "CLEAR_TURNOVER_PERFORMANCE_DATA",
    CLEAR_PROBATION_DATA: "CLEAR_PROBATION_DATA"
});

export const turnoverReducer = produce((draft, action) => {
    switch(action.type) {
        // Turnover Rates
        case TURNOVER_ACTION_TYPES.FETCH_TURNOVER_RATE_DATA:
            draft[componentName.turnoverRate] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case TURNOVER_ACTION_TYPES.CLEAR_TURNOVER_RATE_DATA:
            draft[componentName.turnoverRate] = null;
            return draft;

        // Turnover Types
        case TURNOVER_ACTION_TYPES.FETCH_TURNOVER_TYPE_DATA:
            draft[componentName.turnoverType] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case TURNOVER_ACTION_TYPES.CLEAR_TURNOVER_TYPE_DATA:
            draft[componentName.turnoverType] = null;
            return draft;

        // Turnover Department
        case TURNOVER_ACTION_TYPES.FETCH_TURNOVER_DEPARTMENT_DATA:
            draft[componentName.turnoverDepartment] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case TURNOVER_ACTION_TYPES.CLEAR_TURNOVER_DEPARTMENT_DATA:
            draft[componentName.turnoverDepartment] = null;
            return draft;

        // Turnover Duration
        case TURNOVER_ACTION_TYPES.FETCH_TURNOVER_DURATION_DATA:
            draft[componentName.turnoverDuration] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case TURNOVER_ACTION_TYPES.CLEAR_TURNOVER_DURATION_DATA:
            draft[componentName.turnoverDuration] = null;
            return draft;

        // Turnover Performance
        case TURNOVER_ACTION_TYPES.FETCH_TURNOVER_PERFORMANCE_DATA:
            draft[componentName.turnoverPerformance] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case TURNOVER_ACTION_TYPES.CLEAR_TURNOVER_PERFORMANCE_DATA:
            draft[componentName.turnoverPerformance] = null;
            return draft;

        // Probation
        case TURNOVER_ACTION_TYPES.FETCH_PROBATION_DATA:
            draft[componentName.probation] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case TURNOVER_ACTION_TYPES.CLEAR_PROBATION_DATA:
            draft[componentName.probation] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
