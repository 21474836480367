import produce from "immer";
import moment from "moment";

export const initialState = {
    leadEnquiries: {},
    leadMileStone: [],
    weeklyData: [],
    netLoanBook: {},
    arrearsRate: {},
    arrearsRunOff: {},
    topLenders: {},
    ldasReport: {},
    googleAnalyticsVisitor: {},
    referralSettled: {},
    referralSubmission: {}
};

export const MORTGAGE_OVERVIEW_ACTION_TYPES = Object.freeze({
    FETCHING_LEAD_ENQUIRIES_DATA: "FETCHING_LEAD_ENQUIRIES_DATA",
    FETCHING_LEAD_MILE_STONE_DATA: "FETCHING_LEAD_MILE_STONE_DATA",
    FETCHING_WEEKLY_LENDERS: "FETCHING_WEEKLY_LENDERS",
    FETCHING_LOAN_BOOK_DATA: "FETCHING_LOAN_BOOK_DATA",
    FETCHING_TOP_LENDERS_DATA: "FETCHING_TOP_LENDERS_DATA",
    FETCHING_ARREARS_DATA: "FETCHING_ARREARS_DATA",
    FETCHING_ARREARS_RUN_OFF_DATA: "FETCHING_ARREARS_RUN_OFF_DATA",
    FETCHING_GOOGLE_ANALYTICS_VISITORS: "FETCHING_GOOGLE_ANALYTICS_VISITORS",
    FETCHING_REFERRAL_SETTLED: "FETCHING_REFERRAL_SETTLED",
    FETCHING_REFERRAL_SUBMISSION: "FETCHING_REFERRAL_SUBMISSION",

    CLEAR_LEAD_ENQUIRIES: "CLEAR_LEAD_ENQUIRIES",
    CLEAR_LEAD_MILESTONE: "CLEAR_LEAD_MILESTONE",
    CLEAR_WEEKLY_LENDERS: "CLEAR_WEEKLY_LENDERS",
    CLEAR_LOAN_BOOK: "CLEAR_LOAN_BOOK_DATA",
    CLEAR_TOP_LENDERS: "CLEAR_TOP_LENDERS",
    CLEAR_ARREARS: "CLEAR_ARREARS",
    CLEAR_ARREAR_RUNOFF: "CLEAR_ARREAR_RUNOFF",
    CLEAR_GOOGLE_ANALYTICS_VISITOR: "CLEAR_GOOGLE_ANALYTICS_VISITOR",
    CLEAR_REFERRAL_SETTLED: "CLEAR_REFERRAL_SETTLED",
    CLEAR_REFERRAL_SUBMISSION: "CLEAR_REFERRAL_SUBMISSION"
});

export const mortgageOverviewReducer = produce((draft, action) => {

    switch(action.type) {
        // Lead Enquiries
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_LEAD_ENQUIRIES_DATA:
            draft.leadEnquiries = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_LEAD_ENQUIRIES:
            draft.leadEnquiries = null;
            return draft;

        // Lead MileStone
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_LEAD_MILE_STONE_DATA:
            draft.leadMileStone = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_LEAD_MILESTONE:
            draft.leadMileStone = null;
            return draft;

        // Weekly lenders
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_WEEKLY_LENDERS:
            draft.weeklyData = {
                data: action.payload.data.sort((a, b) => {
                    return moment(a.from, "DD/MM/YYYY").diff(moment(b.from, "DD/MM/YYYY"));
                }),
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_WEEKLY_LENDERS:
            draft.weeklyData = null;
            return draft;

        // Loan Book
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_LOAN_BOOK_DATA:
            draft.netLoanBook = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_LOAN_BOOK_:
            draft.netLoanBook = null;
            return draft;

        // Top Lenders
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_TOP_LENDERS_DATA:
            const array = {
                settlement: [],
                submission: []
            };
            const type = ["settlement", "submission"];

            type.forEach(ele => {
                action.payload.data?.data[ele].forEach(element => {
                    array[ele].push(element.value);
                });
            });
            if(array.settlement.length > 0) {
                const otherSettlementLenders ={
                    lender: "others",
                    value: 100 - array.settlement.reduce((partialSum, a) => partialSum + a, 0).toFixed(2)
                };
                action.payload.data?.data.settlement.push(otherSettlementLenders);
            }
            if(array.submission.length > 0) {
                const otherSubmissionLenders ={
                    lender: "others",
                    value: 100 - array.submission.reduce((partialSum, a) => partialSum + a, 0).toFixed(2)
                };
                action.payload.data?.data.submission.push(otherSubmissionLenders);
            }

            draft.topLenders = {
                ...action.payload.data,
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_TOP_LENDERS:
            draft.topLenders = null;
            return draft;

        // Arrears
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_ARREARS_DATA:
            draft.arrearsRate = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_ARREARS:
            draft.arrearsRate = null;
            return draft;

        // Arrears Runoff
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_ARREARS_RUN_OFF_DATA:
            draft.arrearsRunOff = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_ARREAR_RUNOFF:
            draft.arrearsRunOff = null;
            return draft;

        // Google Analytics Visitor
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_GOOGLE_ANALYTICS_VISITORS:
            draft.googleAnalyticsVisitor = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_GOOGLE_ANALYTICS_VISITOR:
            draft.googleAnalyticsVisitor = null;
            return draft;


        // Referral Settled
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_REFERRAL_SETTLED:
            draft.referralSettled = {
                year: action.payload.data?.year,
                data: action.payload.data?.data?.map(item => {
                    return {
                        year: `${item.Month} ${item.Year}`,
                        ...item
                    };
                }),
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_REFERRAL_SETTLED:
            draft.referralSettled = null;
            return draft;

        // Referral Submission
        case MORTGAGE_OVERVIEW_ACTION_TYPES.FETCHING_REFERRAL_SUBMISSION:
            draft.referralSubmission = {
                year: action.payload.data?.year,
                data: action.payload.data?.data?.map(item => {
                    return {
                        year: `${item.Month} ${item.Year}`,
                        ...item
                    };
                }),
                stateCreatedAt: new Date()
            };
            return draft;

        case MORTGAGE_OVERVIEW_ACTION_TYPES.CLEAR_REFERRAL_SUBMISSION:
            draft.referralSubmission = null;
            return draft;


        default:
            return draft;

    }
}, initialState);
