import produce from "immer";

import { componentName } from "../../constants";

export const initialState = {
    [componentName.leadRatingReport]: {}
};

export const LQS_ACTION_TYPES = Object.freeze({
    FETCH_LQS_REPORT: "FETCH_LQS_REPORT"
});

export const lqsReducer = produce((draft, action) => {
    switch(action.type) {
        case LQS_ACTION_TYPES.FETCH_LQS_REPORT:
            draft= {
                ...action.payload
            };
            return draft;

        default:
            return draft;
    }
}, initialState);
