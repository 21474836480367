import { useEffect } from "react";

export const useClickOutside = ( ref, callback ) => {
    useEffect(() => {
        function handleClickOutside(event) {
            // * Outside Click
            if(ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
};
