import produce from "immer";

export const initialState = {
    dischargeLenders: {},
    dischargeBrokers: {},
    dischargeLps: {},
    discharge: {}
};

export const FORM_DISCHARGE_ACTIONS_TYPES = Object.freeze({
    FETCH_DISCHARGE_LENDERS_DATA: "FETCH_DISCHARGE_LENDERS_DATA",
    CLEAR_DISCHARGE_LENDERS_DATA: "CLEAR_DISCHARGE_LENDERS_DATA",

    FETCH_DISCHARGE_BROKER_DATA: "FETCH_DISCHARGE_BROKER_DATA",
    CLEAR_DISCHARGE_BROKER_DATA: "CLEAR_DISCHARGE_BROKER_DATA",

    FETCH_DISCHARGE_LPS_DATA: "FETCH_DISCHARGE_LPS_DATA",
    CLEAR_DISCHARGE_LPS_DATA: "CLEAR_DISCHARGE_LPS_DATA",

    FETCH_DISCHARGE_DATA: "FETCH_DISCHARGE_DATA",
    CLEAR_DISCHARGE_DATA: "CLEAR_DISCHARGE_DATA"
});

export const dischargeReducer = produce((draft, action) => {

    switch(action.type) {
        // Discharge Data
        case FORM_DISCHARGE_ACTIONS_TYPES.FETCH_DISCHARGE_DATA:
            draft.discharge = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case FORM_DISCHARGE_ACTIONS_TYPES.CLEAR_DISCHARGE_DATA:
            draft.discharge = null;
            return draft;

        // Lenders List
        case FORM_DISCHARGE_ACTIONS_TYPES.FETCH_DISCHARGE_LENDERS_DATA:
            draft.dischargeLenders = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case FORM_DISCHARGE_ACTIONS_TYPES.CLEAR_DISCHARGE_LENDERS_DATA:
            draft.dischargeLenders = null;
            return draft;

        // Brokers List
        case FORM_DISCHARGE_ACTIONS_TYPES.FETCH_DISCHARGE_BROKER_DATA:
            draft.dischargeBrokers = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case FORM_DISCHARGE_ACTIONS_TYPES.CLEAR_DISCHARGE_BROKER_DATA:
            draft.dischargeBrokers = null;
            return draft;

        // LPS List
        case FORM_DISCHARGE_ACTIONS_TYPES.FETCH_DISCHARGE_LPS_DATA:
            draft.dischargeLps = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case FORM_DISCHARGE_ACTIONS_TYPES.CLEAR_DISCHARGE_LPS_DATA:
            draft.dischargeLps = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
