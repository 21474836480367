import Amplify, { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, useHistory, withRouter, useLocation } from "react-router-dom";

import { clearMode, setMode } from "../actions/modeAction";
import Layout from "../components/Common/Layout";
import GenericLoader from "../components/Common/Loaders/GenericLoader";
import config from "../config";
import { useMenuHighlighter } from "../hooks/useLayoutHooks";
import { TV_MODE } from "../reducers/modeReducer";

function initializePendo(user) {
    if(window.pendo)
        window.pendo.initialize({
            visitor: {
                id: user.sub,
                email: user.email,
                full_name: user.name
            },

            account: {
                id: user.sub
            }
        });
}

// Load amplify configs
Amplify.configure(config["amplify"]);
Auth.configure({ oauth: config["oauth"] });

const PrivateRoute = ({ component: Component, noLayout, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const mode = useSelector(state => state?.mode);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                dispatch({ type: "SET_USER_ATTRIBUTE", payload: user.attributes });
                // After Successful Login, Initialize Pendo for User Tracking
                initializePendo(user.attributes);

                setIsAuthenticated(true);
            })
            .catch(() => {
                history.push("/login");
            }).finally(() => {
                setIsLoading(false);
            });
    }, [history, dispatch]);

    useEffect(() => {
        if((location.pathname === "/tv/syd" || location.pathname === "/tv/np") && !mode.tvMode)
            dispatch(setMode(TV_MODE));
        if(location.pathname !== "/tv/syd" && location.pathname !== "/tv/np" && mode.tvMode )
            dispatch(clearMode(TV_MODE));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, dispatch]);

    useMenuHighlighter();

    const renderComponent = (props) => {
        // for tv mode that does not need layout
        if(noLayout)
            return <Component />;

        // #1 is loading for auth
        if(isLoading)
            return <GenericLoader isFullScreen />;

        // #2 loading complete ~ is not authenticated
        if(!isAuthenticated)
            return <Redirect to={{ pathname: "/login" }} />;

        return (
            <Layout>
                <Component/>
            </Layout>
        );
    };

    return (
        <Route
            {...rest}
            render={renderComponent}
        />
    );
};

export default withRouter(PrivateRoute);
