import produce from "immer";

import { componentName } from "../../../constants";

export const initialState = {
    [componentName.staffByYear]: {},
    [componentName.staffByCountry]: {},
    [componentName.staffByAge]: {},
    [componentName.staffByDepartment]: {},
    [componentName.staffByGender]: {}
};

export const PEOPLE_AND_CULTURE_ACTION_TYPES = Object.freeze({
    FETCH_STAFF_BY_YEAR: "FETCH_STAFF_BY_YEAR",
    FETCH_STAFF_BY_COUNTRY: "FETCH_STAFF_BY_COUNTRY",
    FETCH_STAFF_BY_DEPARTMENT: "FETCH_STAFF_BY_DEPARTMENT",
    FETCH_STAFF_BY_GENDER: "FETCH_STAFF_BY_GENDER",
    FETCH_STAFF_BY_AGE: "FETCH_STAFF_BY_AGE",


    CLEAR_STAFF_BY_YEAR: "CLEAR_STAFF_BY_YEAR",
    CLEAR_STAFF_BY_COUNTRY: "CLEAR_STAFF_BY_COUNTRY",
    CLEAR_STAFF_BY_DEPARTMENT: "CLEAR_STAFF_BY_DEPARTMENT",
    CLEAR_STAFF_BY_GENDER: "CLEAR_STAFF_BY_GENDER",
    CLEAR_STAFF_BY_AGE: "CLEAR_STAFF_BY_AGE"
});

export const overviewReducer = produce((draft, action) => {

    switch(action.type) {
        // Staff By Year
        case PEOPLE_AND_CULTURE_ACTION_TYPES.FETCH_STAFF_BY_YEAR:
            draft[componentName.staffByYear] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case PEOPLE_AND_CULTURE_ACTION_TYPES.CLEAR_STAFF_BY_YEAR:
            draft[componentName.staffByYear] = null;
            return draft;

        // Staff By Country
        case PEOPLE_AND_CULTURE_ACTION_TYPES.FETCH_STAFF_BY_COUNTRY:
            draft[componentName.staffByCountry] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case PEOPLE_AND_CULTURE_ACTION_TYPES.CLEAR_STAFF_BY_COUNTRY:
            draft[componentName.staffByCountry] = null;
            return draft;

        // Staff By Department
        case PEOPLE_AND_CULTURE_ACTION_TYPES.FETCH_STAFF_BY_DEPARTMENT:
            draft[componentName.staffByDepartment] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case PEOPLE_AND_CULTURE_ACTION_TYPES.CLEAR_STAFF_BY_DEPARTMENT:
            draft[componentName.staffByDepartment] = null;
            return draft;

        // Staff By Age
        case PEOPLE_AND_CULTURE_ACTION_TYPES.FETCH_STAFF_BY_AGE:
            draft[componentName.staffByAge] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case PEOPLE_AND_CULTURE_ACTION_TYPES.CLEAR_STAFF_BY_AGE:
            draft[componentName.staffByAge] = null;
            return draft;

        // Staff By Gender
        case PEOPLE_AND_CULTURE_ACTION_TYPES.FETCH_STAFF_BY_GENDER:
            draft[componentName.staffByGender] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case PEOPLE_AND_CULTURE_ACTION_TYPES.CLEAR_STAFF_BY_GENDER:
            draft[componentName.staffByGender] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
