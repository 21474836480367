import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route as ReactRoute } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "@hlexperts/pulse-ui-helper/dist/lib.css";
import "./scss/style.scss";

import GenericLoader from "./components/Common/Loaders/GenericLoader";
import NotFound from "./components/Common/NotFound";
import FallbackUI from "./components/ErrorBoundary/FallbackUI";
import { TOAST_DURATION } from "./constants/toasterMessage";
import PrivateRoute from "./routers/PrivateRoute";
import PublicRoute from "./routers/PublicRoute";

// Routes
const MortgageRoutes = React.lazy(() => import("./routes/MortgageRoutes"));
const FormRoutes = React.lazy(() => import("./routes/FormRoutes"));;
const SankeyRoutes = React.lazy(() => import("./routes/SankeyRoutes"));
const MiscellaneousRoutes = React.lazy(() => import("./routes/MiscellaneousRoutes"));
const OutsourcingRoutes = React.lazy(() => import("./routes/OutsourcingRoutes"));
const OperationsRoutes = React.lazy(() => import("./routes/OperationsRoutes"));

const OAuth = React.lazy(() => import("./pages/Login/OAuth"));
const Overview = React.lazy(() => import("./pages/Overview"));
const TVMode = React.lazy(() => import("./pages/TVMode"));

const Route = process.env.NODE_ENV === "production" ? Sentry.withSentryRouting(ReactRoute) : ReactRoute;

function App() {
    const mode = useSelector(state => state?.mode);
    const [showToast, setShowToast] = useState(true);

    useEffect(() => {
        if(mode.tvMode) setShowToast(false);
    }, [mode]);

    return (
        <Sentry.ErrorBoundary fallback={FallbackUI}>
            {showToast && <ToastContainer autoClose={TOAST_DURATION} />}

            <Router>
                <React.Suspense fallback={<GenericLoader isFullScreen />}>
                    <Switch>
                        <PublicRoute path="/login" component={OAuth} />
                        <PrivateRoute exact path="/" component={Overview} />
                        <PrivateRoute path="/tv/syd" component={TVMode} noLayout />
                        <PrivateRoute path="/tv/np" component={TVMode} noLayout />

                        <Route path="/mortgage" component={MortgageRoutes} />
                        <Route path="/operations" component={OperationsRoutes} />
                        <Route path="/outsourcing" component={OutsourcingRoutes} />
                        <Route path="/upload" component={MiscellaneousRoutes} />
                        <Route path="/sankey" component={SankeyRoutes} />
                        <Route path="/forms" component={FormRoutes} />

                        <Route component={NotFound} />
                    </Switch>
                </React.Suspense>
            </Router>
        </Sentry.ErrorBoundary>
    );
}

export default Sentry.withProfiler(App);
