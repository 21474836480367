export const MENU_TITLES = {
    "/": "Company Overview",
    "/mortgage": "Mortgage Broking Overview",
    "/mortgage/marketing": "Marketing",
    "/mortgage/email-marketing": "Email Marketing",
    "/mortgage/reviews-and-ratings": "Reviews And Ratings",
    "/mortgage/ldas-reports": "Mortgage Broking LDAS Reports",
    "/outsourcing": "Outsourcing",
    "/operations": "Operations Overview",
    "/operations/technology": "Technology",
    "/operations/learning-hub": "Learning Hub",
    "/operations/people-and-culture": "People And Culture",
    "/operations/people-and-culture/turnover-trends": "Turnover Trends",
    "/operations/people-and-culture/learning-and-development": "Learning And Development",
    "/operations/people-and-culture/engagement-reports": "Engagement Reports",
    "/operations/call-analysis": "Call Analysis",
    "/operations/chat-analysis": "Chat Analysis",
    "/sankey": "Segment Analysis"
};
