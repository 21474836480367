import produce from "immer";

import { componentName } from "../../../constants";

export const initialState = {
    [componentName.employeeAssistance]: {},
    [componentName.engagementScore]: {},
    [componentName.onboarding]: {},
    [componentName.parentalBonus]: {},
    [componentName.rewards]: {},
    [componentName.semiAnnualEngagement]: {},
    [componentName.weddingGifts]: {},
    [componentName.workAnniversary]: {}
};

export const ENGAGEMENT_ACTION_TYPES = Object.freeze({
    FETCH_EMPLOYEE_ASSISTANCE_DATA: "FETCH_EMPLOYEE_ASSISTANCE_DATA",
    FETCH_ENGAGEMENT_SCORE_DATA: "FETCH_ENGAGEMENT_SCORE_DATA",
    FETCH_ONBOARDING_DATA: "FETCH_ONBOARDING_DATA",
    FETCH_PARENTAL_BONUS_DATA: "FETCH_PARENTAL_BONUS_DATA",
    FETCH_REWARDS_DATA: "FETCH_REWARDS_DATA",
    FETCH_SEMI_ANNUAL_ENGAGEMENT_DATA: "FETCH_SEMI_ANNUAL_ENGAGEMENT_DATA",
    FETCH_WEDDING_GIFTS_DATA: "FETCH_WEDDING_GIFTS_DATA",
    FETCH_WORK_ANNIVERSARY_DATA: "FETCH_WORK_ANNIVERSARY_DATA",

    CLEAR_EMPLOYEE_ASSISTANCE_DATA: "CLEAR_EMPLOYEE_ASSISTANCE_DATA",
    CLEAR_ENGAGEMENT_SCORE_DATA: "CLEAR_ENGAGEMENT_SCORE_DATA",
    CLEAR_ONBOARDING_DATA: "CLEAR_ONBOARDING_DATA",
    CLEAR_PARENTAL_BONUS_DATA: "CLEAR_PARENTAL_BONUS_DATA",
    CLEAR_REWARDS_DATA: "CLEAR_REWARDS_DATA",
    CLEAR_SEMI_ANNUAL_ENGAGEMENT_DATA: "CLEAR_SEMI_ANNUAL_ENGAGEMENT_DATA",
    CLEAR_WEDDING_GIFTS_DATA: "CLEAR_WEDDING_GIFTS_DATA",
    CLEAR_WORK_ANNIVERSARY_DATA: "CLEAR_WORK_ANNIVERSARY_DATA"
});

export const engagementReducer = produce((draft, action) => {
    switch(action.type) {
        // Employee Assistance Program
        case ENGAGEMENT_ACTION_TYPES.FETCH_EMPLOYEE_ASSISTANCE_DATA:
            draft[componentName.employeeAssistance] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case ENGAGEMENT_ACTION_TYPES.CLEAR_EMPLOYEE_ASSISTANCE_DATA:
            draft[componentName.employeeAssistance] = null;
            return draft;

        // Engagement Score
        case ENGAGEMENT_ACTION_TYPES.FETCH_ENGAGEMENT_SCORE_DATA:
            draft[componentName.engagementScore] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case ENGAGEMENT_ACTION_TYPES.CLEAR_ENGAGEMENT_SCORE_DATA:
            draft[componentName.engagementScore] = null;
            return draft;

        // Onboarding
        case ENGAGEMENT_ACTION_TYPES.FETCH_ONBOARDING_DATA:
            draft[componentName.onboarding] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case ENGAGEMENT_ACTION_TYPES.CLEAR_ONBOARDING_DATA:
            draft[componentName.onboarding] = null;
            return draft;

        // Parental Bonus
        case ENGAGEMENT_ACTION_TYPES.FETCH_PARENTAL_BONUS_DATA:
            draft[componentName.parentalBonus] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case ENGAGEMENT_ACTION_TYPES.CLEAR_PARENTAL_BONUS_DATA:
            draft[componentName.parentalBonus] = null;
            return draft;

        // Rewards
        case ENGAGEMENT_ACTION_TYPES.FETCH_REWARDS_DATA:
            draft[componentName.rewards] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case ENGAGEMENT_ACTION_TYPES.CLEAR_REWARDS_DATA:
            draft[componentName.rewards] = null;
            return draft;

        // Semi Annual Engagement
        case ENGAGEMENT_ACTION_TYPES.FETCH_SEMI_ANNUAL_ENGAGEMENT_DATA:
            draft[componentName.semiAnnualEngagement] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case ENGAGEMENT_ACTION_TYPES.CLEAR_SEMI_ANNUAL_ENGAGEMENT_DATA:
            draft[componentName.semiAnnualEngagement] = null;
            return draft;

        // Wedding Gifts
        case ENGAGEMENT_ACTION_TYPES.FETCH_WEDDING_GIFTS_DATA:
            draft[componentName.weddingGifts] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case ENGAGEMENT_ACTION_TYPES.CLEAR_WEDDING_GIFTS_DATA:
            draft[componentName.weddingGifts] = null;
            return draft;

        // Work Anniversary
        case ENGAGEMENT_ACTION_TYPES.FETCH_WORK_ANNIVERSARY_DATA:
            draft[componentName.workAnniversary] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case ENGAGEMENT_ACTION_TYPES.CLEAR_WORK_ANNIVERSARY_DATA:
            draft[componentName.workAnniversary] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
