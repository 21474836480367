import { combineReducers } from "redux";

import { callAnalysisReducer } from "./callAnalysisReducer";
import { chatAnalysisReducer } from "./chatAnalysisReducer";
import { learningHubReducer } from "./learningHubReducer";
import { operationOverviewReducer } from "./operationsOverviewReducer";
import { peopleAndCultureReducer } from "./peopleAndCulture";
import { technologySecondaryDimensionReducer } from "./technologyDimensionReducer";
import { technologyReducer } from "./technologyReducer";

export const operationReducer = combineReducers({
    learningHub: learningHubReducer,
    peopleAndCulture: peopleAndCultureReducer,
    callAnalysis: callAnalysisReducer,
    chatAnalysis: chatAnalysisReducer,
    operationOverview: operationOverviewReducer,
    technology: technologyReducer,
    technologyDimension: technologySecondaryDimensionReducer
});
