import cx from "classnames";
import { capitalize } from "lodash";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import { MdContentCopy } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { ReactComponent as CloseIcon } from "../../../assets/svg/Close.svg";
import { ReactComponent as MenuIcon } from "../../../assets/svg/MenuWhite.svg";
import { componentDetails, componentName } from "../../../constants";
import { MENU_TITLES } from "../../../constants/layout";
import { useClickOutside } from "../../../hooks/useClickOutside";
import styles from "../../../scss/modules/Layout/Menu.module.scss";

const DISCARDED_ITEMS = [
    componentName.arrearsRunOff
];

const ListItem = ({
    id,
    closeMenu,
    isHighlighted,
    title
}) => {

    const [isCopied, setCopied] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if(isCopied) setCopied(false);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied]);

    const handleClipboardCopy = () => {
        if(navigator?.clipboard)
            navigator.clipboard.writeText(`${window.location.origin}${window.location.pathname}#${id}`);
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <a
                    href={`#${id}`}
                    className={cx("w-100", styles.item, isHighlighted && "text-primary")}
                    onClick={closeMenu}
                >
                    <span>{title}</span>
                </a>
                <div className="mx-1" />
                <button
                    className="btn btn-sm p-1 d-flex align-items-center text-muted"
                    onClick={() => {
                        handleClipboardCopy();
                        setCopied(true);
                    }}
                >
                    {!isCopied
                        ? <MdContentCopy fontSize={13}/>
                        : <GiCheckMark className="text-success" fontSize={13} />
                    }
                </button>
            </div>
        </>
    );
};

const Menu = () => {
    const location = useLocation();
    const menuRef = useRef();

    const { activeMenuItem } = useSelector(state => state?.sidebar);


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useClickOutside(menuRef, () => setIsMenuOpen(false));

    const list = useMemo(() => {
        return Object
            .values(componentDetails)
            .filter(item =>
                item.id &&
                item.page === location.pathname &&
                !DISCARDED_ITEMS.includes(item.name)
            );

    }, [location]);

    const title = useMemo(() => {
        const arr = location.pathname.split("/");

        if(MENU_TITLES[location.pathname])
            return MENU_TITLES[location.pathname];

        return arr[arr.length - 1]
            ?.split("-")
            ?.map(str => capitalize(str))
            ?.join(" ") || "Charts List";

    }, [location]);

    if(!list.length) return null;

    return (
        <div ref={menuRef} className={cx("small", styles.container)}>
            <div
                className={cx(
                    "btn rounded-circle shadow border-0",
                    styles.iconBtn,
                    isMenuOpen ? styles["iconBtn-close"] : "btn-primary"
                )}
                onClick={() => setIsMenuOpen(prev => !prev)}
            >
                {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </div>

            {isMenuOpen && (
                <div className={cx("bg-white rounded shadow", styles.listContainer)}>
                    <div className={cx("bg-primary rounded-top", styles.listTitle)}>
                        <strong>{title}</strong>
                    </div>
                    <div className={cx("d-flex flex-column", styles.listItems)}>
                        {list.map(({ id, title }) => (
                            <Fragment key={id}>
                                <ListItem
                                    id={id}
                                    title={title}
                                    isHighlighted={activeMenuItem === id}
                                    closeMenu={() => setIsMenuOpen(false)}
                                />
                            </Fragment>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Menu;
