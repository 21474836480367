import cx from "classnames";
import { Link } from "react-router-dom";

import styles from "../../scss/modules/Common/NotFound.module.scss";

import { Header } from "./Layout/Header";

export default function NotFound() {
    return (
        <>
            <Header />
            <div className={cx(styles["not-found-container"], "d-flex flex-column align-items-center py-5")}>
                <h2>HLE Pulse</h2>
                <p className="display-1 my-3">404</p>
                <p className="lead text-muted">Page Not Found</p>
                <Link to="/" className="btn btn-sm btn-primary my-3 ">Go To Company Overview</Link>
            </div>
        </>
    );
}
