/* eslint-disable no-console */
import { Auth } from "aws-amplify";

/**
 * User Sign Out Action
 * @param {*} dispatch
 */
export const handleSignOut = async(dispatch) => {
    try {
        localStorage.removeItem("state");
        await Auth.currentAuthenticatedUser();
        await Auth.signOut();
        dispatch({ type: "RESET_USER_DATA" });
    } catch(error) {
        console.error("Error signing out user ", error);
        window.location.reload();
    }
};
