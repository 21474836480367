import produce from "immer";

export const initialState = {
    retention: {},
    brokerRetention: {},
    lenderRetention: {}
};

export const DISCHARGE_ACTIONS_TYPES = Object.freeze({
    FETCH_CLAWBACK_RETENTION_DATA: "FETCH_CLAWBACK_RETENTION_DATA",
    FETCH_CLAWBACK_BROKER_RETENTION_DATA: "FETCH_CLAWBACK_BROKER_RETENTION_DATA",
    FETCH_CLAWBACK_LENDER_RETENTION_DATA: "FETCH_CLAWBACK_LENDER_RETENTION_DATA",

    CLEAR_CLAWBACK_RETENTION_DATA: "CLEAR_CLAWBACK_RETENTION_DATA",
    CLEAR_CLAWBACK_BROKER_RETENTION_DATA: "CLEAR_CLAWBACK_BROKER_RETENTION_DATA",
    CLEAR_CLAWBACK_LENDER_RETENTION_DATA: "CLEAR_CLAWBACK_LENDER_RETENTION_DATA"
});

export const dischargeReducer = produce((draft, action) => {
    switch(action.type) {
        // Customer Retention
        case DISCHARGE_ACTIONS_TYPES.FETCH_CLAWBACK_RETENTION_DATA:
            draft.retention = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case DISCHARGE_ACTIONS_TYPES.CLEAR_CLAWBACK_RETENTION_DATA:
            draft.retention = null;
            return draft;

        // Customer Retention by Broker
        case DISCHARGE_ACTIONS_TYPES.FETCH_CLAWBACK_BROKER_RETENTION_DATA:
            draft.brokerRetention = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case DISCHARGE_ACTIONS_TYPES.CLEAR_CLAWBACK_BROKER_RETENTION_DATA:
            draft.brokerRetention = null;
            return draft;

        // Customer Retention by Lender
        case DISCHARGE_ACTIONS_TYPES.FETCH_CLAWBACK_LENDER_RETENTION_DATA:
            draft.lenderRetention = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case DISCHARGE_ACTIONS_TYPES.CLEAR_CLAWBACK_LENDER_RETENTION_DATA:
            draft.lenderRetention = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
