import produce from "immer";

export const initialState = {
    outsourcingStaff: {},
    outsourcingRevenue: {},
    outsourcingLps: {},
    outsourcingCa: {}
};

export const OUTSOURCING_ACTION_TYPES = Object.freeze({
    FETCHING_OUTSOURCING_STAFF_DATA: "FETCHING_OUTSOURCING_STAFF_DATA",
    FETCHING_OUTSOURCING_REVENUE_DATA: "FETCHING_OUTSOURCING_REVENUE_DATA",
    FETCHING_OUTSOURCING_LPS_DATA: "FETCHING_OUTSOURCING_LPS_DATA",
    FETCHING_OUTSOURCING_CA_DATA: "FETCHING_OUTSOURCING_CA_DATA",

    CLEAR_OUTSOURCING_STAFF_DATA: "CLEAR_OUTSOURCING_STAFF_DATA",
    CLEAR_OUTSOURCING_REVENUE_DATA: "CLEAR_OUTSOURCING_REVENUE_DATA",
    CLEAR_OUTSOURCING_LPS_DATA: "CLEAR_OUTSOURCING_LPS_DATA",
    CLEAR_OUTSOURCING_CA_DATA: "CLEAR_OUTSOURCING_CA_DATA"
});

export const outsourcingReducer = produce((draft, action) => {
    switch(action.type) {
        // Oursourcing Staff
        case OUTSOURCING_ACTION_TYPES.FETCHING_OUTSOURCING_STAFF_DATA:
            draft.outsourcingStaff = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case OUTSOURCING_ACTION_TYPES.CLEAR_OUTSOURCING_STAFF_DATA:
            draft.outsourcingStaff = null;
            return draft;

        // Oursourcing Revenue Data
        case OUTSOURCING_ACTION_TYPES.FETCHING_OUTSOURCING_REVENUE_DATA:
            draft.outsourcingRevenue = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case OUTSOURCING_ACTION_TYPES.CLEAR_OUTSOURCING_REVENUE_DATA:
            draft.outsourcingRevenue = null;
            return draft;

        // Outsourcing LPS Data
        case OUTSOURCING_ACTION_TYPES.FETCHING_OUTSOURCING_LPS_DATA:
            draft.outsourcingLps = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case OUTSOURCING_ACTION_TYPES.CLEAR_OUTSOURCING_LPS_DATA:
            draft.outsourcingLps = null;
            return draft;


        // Outsourcing CA Data
        case OUTSOURCING_ACTION_TYPES.FETCHING_OUTSOURCING_CA_DATA:
            draft.outsourcingCa = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case OUTSOURCING_ACTION_TYPES.CLEAR_OUTSOURCING_CA_DATA:
            draft.outsourcingCa = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
