import { combineReducers } from "redux";

import { activityReportsReducer } from "./activityReportsReducer";
import { brokerReportsReducer } from "./brokerReportsReducer";
import { dischargeReducer } from "./dischargeReducer";
import { emailMarketingReducer } from "./emailMarketingReducer";
import { ldasReportsReducer } from "./ldasReportsReducer";
import { lqsReducer } from "./lqsReportsReducer";
import { marketingReducer } from "./marketingReducer";
import { mortgageOverviewReducer } from "./mortgageOverviewReducer";
import { reviewAndRatingReducer } from "./reviewAndRatingReducer";
import { salesReportsReducer } from "./salesReportsReducer";
import { workloadReportsReducer } from "./workloadReportsReducer";

export const mortgageReducer = combineReducers({
    discharge: dischargeReducer,
    emailMarketing: emailMarketingReducer,
    mortgageOverview: mortgageOverviewReducer,
    ldasReports: ldasReportsReducer,
    reviewAndRating: reviewAndRatingReducer,
    activityReports: activityReportsReducer,
    salesReports: salesReportsReducer,
    workloadReports: workloadReportsReducer,
    brokerReports: brokerReportsReducer,
    marketing: marketingReducer,
    leadRatingReport: lqsReducer
});
