import produce from "immer";

import { componentName } from "../../constants";

export const initialState = {
    [componentName.loanBook]: {}
};

export const BROKER_REPORTS_ACTION_TYPES = Object.freeze({
    FETCH_LOAN_BOOK_DATA: "FETCH_LOAN_BOOK_DATA",

    CLEAR_LOAN_BOOK_DATA: "CLEAR_LOAN_BOOK_DATA"
});

export const brokerReportsReducer = produce((draft, action) => {
    switch(action.type) {
        // LPS & Learning Hub Workloads
        case BROKER_REPORTS_ACTION_TYPES.FETCH_LOAN_BOOK_DATA:
            draft[componentName.loanBook] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case BROKER_REPORTS_ACTION_TYPES.CLEAR_LOAN_BOOK_DATA:
            draft[componentName.loanBook] = null;
            return draft;

        default:
            return draft;
    }
}, initialState);
