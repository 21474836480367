// All months param range from 1 - 12

export const getDaysInMonth = (year, month) => {
    // (2022, 12) -> 31 days
    if(!year || !month) return;
    return new Date(year, month, 0).getDate();
};

export const getStartWeekDay = (year, month) => {
    // (2022, 12) -> 4 (Thu)
    // 0 -> Sun, 1 -> Mon, 2-> Tue ...
    if(!year || !month) return;
    return new Date(`${year}-${month}-1`).getDay();
};

export const getCurrentDate = () => {
    const date = new Date();
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    };
};

export const getDays = (year, month) => {
    const startWeek = getStartWeekDay(year, month);
    const daysInMonth = getDaysInMonth(year, month);
    const totalDays = 42;

    const days = [];
    let day = 1;
    for(let i = 0; i < totalDays; i++) {
        if(i < startWeek) {
            days.push(null);
            continue;
        }
        if(i > (daysInMonth + startWeek - 1)) {
            days.push(null);
            continue;
        }
        days.push(day);
        day += 1;
    }

    return {
        year,
        month,
        days
    };
};

export const getFirstDayOfCurrentMonth = () => {
    const today = new Date();
    const startOfMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1));
    return startOfMonth.toISOString().split("T")[0];
};

export const getDate30DaysAgo = () => {
    const today = new Date();
    const thirtyDaysBefore = new Date(today);
    thirtyDaysBefore.setDate(today.getDate() - 30);
    return thirtyDaysBefore.toISOString().split("T")[0];
};
