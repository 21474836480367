import produce from "immer";

import { componentName } from "../../constants";

export const initialState = {
    [componentName.pageSegment]: {},
    [componentName.leadQuality]: {}
};

export const MARKETING_ACTION_TYPES = Object.freeze({
    FETCHING_PAGE_SEGMENT_DATA: "FETCHING_PAGE_SEGMENT_DATA",
    FETCH_LEAD_QUALITY_DATA: "FETCH_LEAD_QUALITY_DATA",

    CLEAR_PAGE_SEGMENT_DATA: "CLEAR_PAGE_SEGMENT_DATA",
    CLEAR_LEAD_QUALITY_DATA: "CLEAR_LEAD_QUALITY_DATA"
});

export const marketingReducer = produce((draft, action) => {
    switch(action.type) {
        // Page Segment Report
        case MARKETING_ACTION_TYPES.FETCHING_PAGE_SEGMENT_DATA:
            draft[componentName.pageSegment] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case MARKETING_ACTION_TYPES.CLEAR_PAGE_SEGMENT_DATA:
            draft[componentName.pageSegment] = null;
            return draft;

        // Lead Quality Report
        case MARKETING_ACTION_TYPES.FETCH_LEAD_QUALITY_DATA:
            draft[componentName.leadQuality] = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case MARKETING_ACTION_TYPES.CLEAR_LEAD_QUALITY_DATA:
            draft[componentName.leadQuality] = null;
            return draft;


        default:
            return draft;
    }
}, initialState);
