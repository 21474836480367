/**
 * Load redux state saved in local storage
 */
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem("state");
        if(serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch(err) {
        return undefined;
    }
};

/**
 * Save state of redux form in local storage
 * @param {*} state
 */
export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state", serializedState);
    } catch(err) {
    }
};
